// import React, { createContext, useState, useEffect } from "react";

// export const PermissionsContext = createContext(null);

// export function PermissionsProvider({ children }) {
//   const [permissions, setPermissions] = useState(() => {
//     // Retrieve permissions from localStorage (if available)
//     const storedPermissions = localStorage.getItem("permissions");
//     return storedPermissions ? JSON.parse(storedPermissions) : null;
//   });

//   const fetchPermissions = async (userRole, userType, token) => {
//     console.log("[PermissionsContext] fetchPermissions called with:", {
//       userRole,
//       userType,
//       token,
//     });

//     try {
//       const response = await fetch("https://solar-api.antsai.in/api/role/factoryroles/modules", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           user_role: userRole, // e.g., "sys admin"
//           user_type: userType, // e.g., "entity_user"
//         }),
//       });

//       if (!response.ok) {
//         console.error("[PermissionsContext] fetchPermissions -> Response not OK", response.status);
//         return;
//       }

//       const data = await response.json();
//       console.log("[PermissionsContext] fetchPermissions -> received data:", data);

//       // Save permissions to localStorage
//       localStorage.setItem("permissions", JSON.stringify(data));

//       setPermissions(data);
//     } catch (error) {
//       console.error("Error fetching permissions:", error);
//     }
//   };

//   const clearPermissions = () => {
//     localStorage.removeItem("permissions");
//     setPermissions(null);
//   };

//   // Add useEffect to fetch permissions on component load
//   useEffect(() => {
//     const userRole = sessionStorage.getItem("userRole"); // Example: Retrieve from sessionStorage
//     const userType = sessionStorage.getItem("userType"); // Example: Retrieve from sessionStorage
//     const token = sessionStorage.getItem("authToken"); // Example: Retrieve from sessionStorage

//     if (userRole && userType && token) {
//       fetchPermissions(userRole, userType, token);
//     }
//   }, []); // Runs only once on component mount

//   return (
//     <PermissionsContext.Provider value={{ permissions, fetchPermissions, clearPermissions }}>
//       {children}
//     </PermissionsContext.Provider>
//   );
// }


import React, { createContext, useState, useEffect } from "react";

export const PermissionsContext = createContext(null);

export function PermissionsProvider({ children }) {
  const [permissions, setPermissions] = useState(() => {
    // Retrieve permissions from localStorage (if available)
    const storedPermissions = localStorage.getItem("permissions");
    return storedPermissions ? JSON.parse(storedPermissions) : null;
  });

  // Updated fetchPermissions: Now accepts userValue as well.
  const fetchPermissions = async (userRole, userType, userValue, token) => {
    console.log("[PermissionsContext] fetchPermissions called with:", {
      userRole,
      userType,
      userValue,
      token,
    });

    try {
      const response = await fetch("https://solar-api.antsai.in/api/role/factoryroles/modules", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_role: userRole, // e.g., "sys admin"
          user_type: userType, // e.g., "entity_user"
          user_value: userValue,
        }),
      });

      if (!response.ok) {
        console.error("[PermissionsContext] fetchPermissions -> Response not OK", response.status);
        return;
      }

      const data = await response.json();
      console.log("[PermissionsContext] fetchPermissions -> received data:", data);

      // Save permissions to localStorage
      localStorage.setItem("permissions", JSON.stringify(data));
      setPermissions(data);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const clearPermissions = () => {
    localStorage.removeItem("permissions");
    setPermissions(null);
  };

  // Fetch permissions on component load
  useEffect(() => {
    const userRole = sessionStorage.getItem("userRole"); // Example: Retrieve from sessionStorage
    const userType = sessionStorage.getItem("userType"); // Example: Retrieve from sessionStorage
    const userValue = sessionStorage.getItem("userValue"); // Retrieve userValue from sessionStorage
    const token = sessionStorage.getItem("authToken"); // Retrieve token from sessionStorage

    if (userRole && userType && userValue && token) {
      fetchPermissions(userRole, userType, userValue, token);
    }
  }, []); // Runs only once on component mount

  return (
    <PermissionsContext.Provider value={{ permissions, fetchPermissions, clearPermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
}
