import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "./../PlantsTable.css";
import updownIcon from "../../../assets/updown_icon.png";
import GroupView from "./GroupView";
import { DeleteOutlined } from "@mui/icons-material";

function GroupTable({ group, setGroup, selectedRows }) {

    const [sortGroup, setSortGroup] = useState([]);
    const [columns, setColumns] = useState(['groupId', 'groupName'])
    const [groupName,setGroupName]=useState('');
    const [slectedGroup,setSelectedGroup]= useState([]);

    function Styles() {
        return <style>{`
           .save-button {
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}
.table-header {
  padding: 5px 10px 5px 10px !important;
}    
    
            `}</style>
    }

    useEffect(()=>{

    },[group,slectedGroup])

    const saveNewGroup=()=>{
        if(selectedRows.length ===0) return;
         if(groupName==='') {
          alert('Kindly Enter Group Name')
         }else{
           setGroup([...group,{'groupId':group.length+1,'groupName':groupName,'groupDetails':[...selectedRows]}])
         }
    }

    const setSelectedGroups=(index)=>{
        setSelectedGroup([group[index]])
    }

    const deleteGroup=(index)=>{
        setSelectedGroup([...group.splice(index,1)])
    }

    return (

        <div>
            <Styles />
            <Row className=" m-1 mb-2 d-flex flex-row justify-content-left justify-itmes-center gap-1">
                <Col md={3} className="align-self-center ps-0 ms-0">
                    <Form.Label className="text-nowrap">New Group </Form.Label>
                </Col><Col md={6} className="ps-0 ms-0">
                    <Form.Control onChange={(e)=>setGroupName(e.currentTarget.value)} name="groupName" type="string" placeholder="New Group Name" />
                </Col>
                <Col><div className="d-flex align-items-center justify-content-end "> <button className="save-button" onClick={() => saveNewGroup()}>Save</button></div></Col>
            </Row>

            <div className="" style={{ width: "fit-content" }}>
                <div className="w-auto d-flex " style={{ overflowX: "auto" }}>
                    <div className="border rounded border-light-subtle table-container">
                        <table style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr>
                                    {columns.map((column) => (
                                        <th key={column} className="table-header">
                                            {column.charAt(0).toUpperCase() + column.slice(1)}
                                            <img
                                                src={updownIcon}
                                                alt="sort"
                                                onClick={() => handleSort(column)}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </th>
                                    ))}
                                    <th className="table-header"></th>
                                </tr>
                               
                            </thead>
                            <tbody>
                                {/* Rendering filtered rows */}
                                {group.length > 0 ? group.map((rowdata, index) => (
                                    <tr key={rowdata.id} onClick={() => setSelectedGroups(index)}>
                                        {columns.map((column) => (
                                            <td key={`${rowdata.id}-${column}`} className="table-header"> {rowdata[column]} </td>
                                        ))}
                                        <td className="delete-column table-header" >
                                            <DeleteOutlined onClick={() => { deleteGroup(index) }} />
                                        </td>
                                    </tr>
                                )) : <tr key="d-row">
                                    {columns.map((column) => (

                                        <td></td>

                                    ))}
                                    <td className="delete-column table-header" >
                                            <DeleteOutlined  />
                                        </td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="d-flex justify-items-end mx-2"><div className="d-flex align-items-end mt-2"> <button className="save-button" onClick={() => saveNewGroup()}>Save</button></div></div> */}
                </div>
            </div>
            <GroupView group={slectedGroup} />
        </div>
    )
}
export default GroupTable;
