import React, { useState, useEffect } from 'react';
import { Container, Row, Accordion, Stack, Col, Form } from "react-bootstrap";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// =========== 1) Define submodules by accordion "sectionKey" ===========
const adminModule = [
  "Entity", "Entity Table", "Add Entity", "Edit Entity", "Plant",
  "Plant Table", "Add Plant", "Edit Plant", "Role", "User",
  "User Table", "Add User", "Edit User", "Catalog", "Report Templates", "Settings"
];
const dashboardModule = [
  "Production Overview", "Performance Alerts", "Power Normalisation Ranks",
  "Plant Status", "Peak hour Ranking", "Environmental Benefits",
  "Historical Production", "Predictive Analytics", "Anomaly detection", "Weather"
];
const plantOverviewModule = ["Peak Power", "Alerts", "Production", "Add Plant"];
const plantModule = ["Plant Details", "Devices", "Plant Analytics", "Edit plant", "New Ticket"];
const maintenanceModule = ["Maintenance", "Edit Ticket"];
const analyticModule = ["Analytics", "Analytics Widget 1", "Analytics Widget 2"];
const reportModule = ["Report", "Add Report", "Edit Report"];

// Map "sectionKey" => array of submodules
const sectionModules = {
  "1": adminModule,
  "2": dashboardModule,
  "3": plantOverviewModule,
  "4": plantModule,
  "5": maintenanceModule,
  "6": analyticModule,
  "7": reportModule
};

// =========== 2) Build initial `permissions` ===========
function generateInitialPermissions(roles) {
  const initial = {};
  roles.forEach((role) => {
    initial[role] = {};
    Object.entries(sectionModules).forEach(([sectionKey, modArray]) => {
      initial[role][sectionKey] = {
        all: false,
        modules: modArray.reduce((acc, cur) => {
          acc[cur] = false;
          return acc;
        }, {})
      };
    });
  });
  return initial;
}

// =========== 3) Map API role name => local column title ===========
function mapApiRoleNameToLocalTitle(apiRoleName) {
  switch (apiRoleName.toLowerCase()) {
    case "sys admin":       return "System Administrator";
    case "plant manager":   return "Plant Manager";
    case "field executive": return "Field Engineer";
    case "individual":      return "Individual";
    default:
      return null; 
  }
}

// =========== 4) Map module_name => accordion sectionKey ===========
function getSectionKeyFromModuleName(moduleName) {
  switch (moduleName) {
    case "Admin":          return "1";
    case "Dashboard":      return "2";
    case "Plant Overview": return "3";
    case "Plant":          return "4";
    case "Maintenance":    return "5";
    case "Analytics":      return "6";
    case "Reports":        return "7";
    default:
      return null;
  }
}

// function Roles() {
//   // ===== A) Define local UI columns for roles =====
//   const [module, setModule] = useState([
//     { title: "System Administrator", status: false },
//     { title: "Plant Manager",        status: false },
//     { title: "Field Engineer",       status: false },
//     { title: "Individual",           status: false },
//   ]);

function Roles() {
  // ===== A) Define local UI columns for roles =====
  // Initialize all roles as disabled. We'll later update them based on the logged-in user's role.
  const [module, setModule] = useState([
    { title: "System Administrator", status: false, disabled: true },
    { title: "Plant Manager",        status: false, disabled: true },
    { title: "Field Engineer",       status: false, disabled: true },
    { title: "Individual",           status: false, disabled: true },
  ]);

  // ===== B) Accordion open/close state =====
  const [activeKeys, setActiveKeys] = useState([]); // e.g. ["1","2"]

  // ===== C) Permissions =====
  const rolesList = module.map((r) => r.title);
  const [permissions, setPermissions] = useState(
    generateInitialPermissions(rolesList)
  );

  //  // ===== D) Retrieve userData from sessionStorage =====
  //  useEffect(() => {
  //   // Retrieve userData and authToken from session storage
  //   const userData = JSON.parse(sessionStorage.getItem("userData"));
  //   // console.log("userData:", userData);
    
  //   if (userData) {
  //     // Extract properties as needed
  //     const { entityId, token, user_role, user_type, userValue } = userData;
      
  //     // Update the role columns based on logged-in user's role.
  //     setModule((prev) =>
  //       prev.map((r) => {
  //         // If no role is selected yet, keep all roles disabled
  //         // Then, enable only those columns which are allowed per the logged-in user.
  //         if (user_role.toLowerCase() === "sys admin") {
  //           // For sys admin, disable System Administrator column; enable the others.
  //           return r.title === "System Administrator" ? { ...r, disabled: true } : { ...r, disabled: false };
  //         } else if (user_role.toLowerCase() === "plant manager") {
  //           // For plant manager, disable System Administrator and Plant Manager columns; enable others.
  //           return (r.title === "System Administrator" || r.title === "Plant Manager")
  //             ? { ...r, disabled: true }
  //             : { ...r, disabled: false };
  //         } else {
  //           // For other user types, you might decide to enable all columns (or keep them disabled until a selection is made).
  //           return { ...r, disabled: true };
  //         }
  //       })
  //     );
      
  //     // You could also conditionally trigger a permissions refresh or other side effects here,
  //     // such as calling fetchPermissions if you want to update permissions context.
  //   } else {
  //     console.warn("No userData found in sessionStorage.");
  //   }
  // }, []);

 // ===== D) Retrieve userData from sessionStorage and update role columns =====
 useEffect(() => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  if (userData) {
    const { user_role } = userData;
    setModule((prev) =>
      prev.map((r) => {
        if (user_role.toLowerCase() === "sys admin") {
          // For sys admin, disable only "System Administrator" column.
          return r.title === "System Administrator"
            ? { ...r, disabled: true }
            : { ...r, disabled: false };
        } else if (user_role.toLowerCase() === "plant manager") {
          // For plant manager, disable "System Administrator" and "Plant Manager" columns.
          return (r.title === "System Administrator" || r.title === "Plant Manager")
            ? { ...r, disabled: true }
            : { ...r, disabled: false };
        } else {
          return { ...r, disabled: true };
        }
      })
    );
  } else {
    console.warn("No userData found in sessionStorage.");
  }
}, []);

// ===== E) Fetch roles permissions if needed =====
useEffect(() => {
  fetchAllRolesPermissions();
}, []);

const fetchAllRolesPermissions = async () => {
  try {
    // read authToken from session storage (if you still want to use this key)
    const authToken = sessionStorage.getItem("authToken") || "";
    // Here you can also retrieve userData to get other required parameters
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const userRole = userData?.user_role || "";
    const userType = userData?.user_type || "";

    // console.log("Fetching roles permissions with:", { authToken, userRole, userType });

    const response = await fetch(
      "https://solar-api.antsai.in/api/role/factoryroles/allroles",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify({
          user_type: userType,
          user_role: userRole
        })
      }
    );

    if (!response.ok) {
      const text = await response.text();
      console.error(`Error! status: ${response.status}`, text);
      throw new Error(`Error! status: ${response.status}`);
    }

    const data = await response.json();
    // console.log("Fetched data from API =>", data);

    if (!data.roles) {
      console.warn("No 'roles' key in the response", data);
      return;
    }
      // Build updated permissions
      setPermissions((prev) => {
        const updated = JSON.parse(JSON.stringify(prev)); // deep clone

        // data.roles => { "sys admin": {Admin:[...], ...}, "plant manager": {...}, ... }
        Object.entries(data.roles).forEach(([apiRoleName, modulesObj]) => {
          const localRoleTitle = mapApiRoleNameToLocalTitle(apiRoleName);
          if (!localRoleTitle) return; // skip if mismatch

          // modulesObj => { "Admin":[...], "Dashboard":[...], ...}
          Object.entries(modulesObj).forEach(([moduleName, widgetsArray]) => {
            const sectionKey = getSectionKeyFromModuleName(moduleName);
            if (!sectionKey) return; // skip unknown module

            widgetsArray.forEach((w) => {
              const widgetName = w.widget_name;
              const hasPermission = w.permission.toLowerCase() === "yes";

              // If the local submodule exists => set it
              if (
                updated[localRoleTitle] &&
                updated[localRoleTitle][sectionKey] &&
                updated[localRoleTitle][sectionKey].modules.hasOwnProperty(widgetName)
              ) {
                updated[localRoleTitle][sectionKey].modules[widgetName] = hasPermission;
              } else {
                // If not found in local list, you can add it dynamically
                if (updated[localRoleTitle] && updated[localRoleTitle][sectionKey]) {
                  updated[localRoleTitle][sectionKey].modules[widgetName] = hasPermission;
                }
              }
            });

            // Then check if "All Modules" is fully checked
            const allValues = Object.values(updated[localRoleTitle][sectionKey].modules);
            updated[localRoleTitle][sectionKey].all = allValues.every(Boolean);
          });
        });

        return updated;
      });

  //     // Make all roles active so we can see them
  //     setModule((prev) => prev.map((r) => ({ ...r, status: true })));
  //   } catch (error) {
  //     console.error("Error fetching roles data:", error);
  //   }
  // };

  // // Debug: watch changes in `permissions`
  // useEffect(() => {
  //   // console.log("Mapped permissions =>", permissions);
  // }, [permissions]);

       // Do not automatically enable any column here;
      // the column enabling happens only when a user selects one.
    } catch (error) {
      console.error("Error fetching roles data:", error);
    }
  };

  // ===== E) "All Modules" toggle per row & role =====
  const handleAllModulesChange = (roleTitle, sectionKey) => {
    const wasAllChecked = permissions[roleTitle][sectionKey].all;
    const newAllValue = !wasAllChecked;

    const newModules = { ...permissions[roleTitle][sectionKey].modules };
    Object.keys(newModules).forEach((m) => {
      newModules[m] = newAllValue;
    });

    setPermissions((prev) => ({
      ...prev,
      [roleTitle]: {
        ...prev[roleTitle],
        [sectionKey]: {
          all: newAllValue,
          modules: newModules
        }
      }
    }));
  };

  // ===== F) Individual submodule toggle =====
  const handleSubModuleChange = (roleTitle, sectionKey, subModule) => {
    const currentVal = permissions[roleTitle][sectionKey].modules[subModule];
    const newVal = !currentVal;

    const newModules = {
      ...permissions[roleTitle][sectionKey].modules,
      [subModule]: newVal
    };
    const allAreChecked = Object.values(newModules).every(Boolean);

    setPermissions((prev) => ({
      ...prev,
      [roleTitle]: {
        ...prev[roleTitle],
        [sectionKey]: {
          all: allAreChecked,
          modules: newModules
        }
      }
    }));
  };

  // ===== G) Accordion expand/collapse logic =====
  const toggleAll = () => {
    const allKeys = Object.keys(sectionModules);
    if (activeKeys.length === allKeys.length) setActiveKeys([]);
    else setActiveKeys(allKeys);
  };

  const toggleItem = (key) => {
    if (activeKeys.includes(key)) {
      setActiveKeys(activeKeys.filter((k) => k !== key));
    } else {
      setActiveKeys([...activeKeys, key]);
    }
  };

  // ===== H) Only one role active at a time (optional) =====
  const handleModuleSelection = (selectedRole) => {
    const updated = module.map((r) => ({
      ...r,
      status: r.title === selectedRole.title
    }));
    setModule(updated);
    // console.log(`[handleModuleSelection] Selected Role: ${selectedRole.title}`);
  };

  // ===== I) Styles =====
  const Styles = () => (
    <style>{`
      .role-title {
        font-family: 'Open Sans', sans-serif; 
        font-weight: 700; 
        color: #000;
        font-size: 24px; 
        margin: 10px 0; 
        padding: 5px 10px;
      }
      .toggle-icon {
        font-size: 15px; 
        font-weight: bold; 
        border: 5px black;
        box-shadow: 0px 0px 0px 1px grey; 
        border-radius: 2px;
      }
      .module-row { 
        background-color: #f2f5f7; 
        height: 100px; 
        display: flex; 
        align-items: center; 
      }
      .admin-row, .plantoverview-row, .maintenance-row, .report-row {
        height: 50px; 
        background-color: white; 
        display: flex; 
        align-items: center;
      }
      .dashboard-row, .plant-row, .analytic-row {
        height: 50px; 
        background-color: #f2f5f7; 
        display: flex; 
        align-items: center;
      }
      .dashboard-body, .plant-body, .analytic-body { 
        background-color: #f2f5f7; 
      }
      .role-item { 
        border-left:2px; 
        border-right:2px; 
        border-top:0; 
        border-bottom:0; 
      }
      .accordion-role {
        padding: 0; 
        border: 0; 
        box-shadow: 0px 0px 1px 1px lightgrey; 
        border-radius: 2px;
        font-size: 18px; 
        font-weight: 400; 
        color: #0F0D0D;
      }
      .text-extra,
      .checkbox-text,
      .module-item {
        font-family: 'Open Sans', sans-serif; 
        font-weight: 700; 
        color: #000;
      }
      .text-extra { font-size: 18px; }
      .body-extra { padding-bottom: 20px; }
      .module-item {
        width: 95%; 
        height: 70px; 
        box-shadow: 1px 2px 3px #EFEFEF90;
        border: 1px solid grey; 
        border-radius: 9px; 
        background: white;
        display: flex; 
        justify-content: center; 
        align-items: center; 
        text-align: center;
      }
      .module-item:hover { cursor: pointer; }
      .role-button {
        padding: 2px 10px; 
        background-color: #167DB0; 
        color: #fff; 
        border: none; 
        border-radius:5px;
        box-shadow: 1px 2px 3px #3435442F; 
        font-size: 18px; 
        cursor: pointer;
      }
      .role-input {
        padding:2px 5px; 
        border: 1px solid #C2CAD2; 
        border-radius:9px;
        box-shadow: 1px 2px 3px #EFEFEF90; 
        font-size: 20px;
      }
      .module-select { 
        border-color:#167DB0; 
        border-width:5px; 
      }
       .disabled-column {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
      .module-row .col,
      .role-item .col {
        border-width: 0px 0px 0px 0px;
        box-shadow: -1px 0px 0px lightgray;
        border-style: solid;
        border-color: grey;
        height: inherit;
        display: flex;
        align-items: center;
      }
      .clickable-row {
        cursor: pointer;
      }
    `}</style>
  );

  // ===== J) Icons for toggles =====
  const iconForItem = (key) =>
    activeKeys.includes(key)
      ? (
        <RemoveIcon
          className="toggle-icon"
          style={{ color: "black", background: "white" }}
        />
      ) : (
        <AddIcon
          className="toggle-icon"
          style={{ color: "black", background: "lightgrey" }}
        />
      );

  const iconForAll = () =>
    activeKeys.length === 0 ? (
      <AddIcon
        className="toggle-icon"
        style={{ color: "black", background: "lightgrey" }}
      />
    ) : (
      <RemoveIcon
        className="toggle-icon"
        style={{ color: "black", background: "white" }}
      />
    );

  // ===== K) Render =====
  return (
    <Container fluid className="mw-100">
      <Styles />

      <Row className="mt-2 ml-1 pt-2">
        <div className="d-flex justify-content-left align-items-center role-title">
          <span>Role Based Access</span>
        </div>
      </Row>

      <Row>
        <Accordion activeKey={activeKeys} className="accordion-role">
          {/* TOP ROW: Roles (columns) */}
          <Row className="module-row px-0 mx-0">
            <Col>
              <div
                className="d-flex flex-row align-items-center gap-2 clickable-row"
                onClick={toggleAll}
              >
                {iconForAll()}
                <div className="text-extra"> Module</div>
              </div>
            </Col>
            {module.map((m) => (
              <Col className="module-col" key={m.title}>
                <div
                  className={`module-item text-extra ${
                    m.status ? "module-select" : ""
                  }`}
                  onClick={() => handleModuleSelection(m)}
                >
                  {m.title}
                </div>
              </Col>
            ))}
          </Row>

          {/* Now each ACCORDION ITEM: Admin=1, Dashboard=2, etc. */}
          {Object.keys(sectionModules).map((sectionKey) => {
            let label = "";
            switch (sectionKey) {
              case "1":
                label = "Admin";
                break;
              case "2":
                label = "Dashboard";
                break;
              case "3":
                label = "Plant Overview";
                break;
              case "4":
                label = "Plant";
                break;
              case "5":
                label = "Maintenance";
                break;
              case "6":
                label = "Analytics";
                break;
              case "7":
                label = "Reports";
                break;
              default:
                label = "Section";
            }

            // Classes for row & body
            let rowClass = "";
            let bodyClass = "";
            switch (sectionKey) {
              case "1":
                rowClass = "admin-row";
                bodyClass = "admin-body";
                break;
              case "2":
                rowClass = "dashboard-row";
                bodyClass = "dashboard-body";
                break;
              case "3":
                rowClass = "plantoverview-row";
                bodyClass = "plantoverview-body";
                break;
              case "4":
                rowClass = "plant-row";
                bodyClass = "plant-body";
                break;
              case "5":
                rowClass = "maintenance-row";
                bodyClass = "maintenance-body";
                break;
              case "6":
                rowClass = "analytic-row";
                bodyClass = "analytic-body";
                break;
              case "7":
                rowClass = "report-row";
                bodyClass = "report-body";
                break;
              default:
                rowClass = "";
                bodyClass = "";
            }

            return (
              <Accordion.Item
                key={sectionKey}
                eventKey={sectionKey}
                className="role-item"
              >
                {/* HEADER ROW: module label + "All Modules" checkboxes */}
                <Row className={`${rowClass} px-0 mx-0`}>
                  <Col>
                    <div
                      className="d-flex flex-row align-items-center gap-2 clickable-row"
                      onClick={() => toggleItem(sectionKey)}
                    >
                      {iconForItem(sectionKey)}
                      <div className="text-extra">{label}</div>
                    </div>
                  </Col>

                  {module.map((r) => {
                    const allChecked = permissions[r.title][sectionKey].all;
                    return (
                      <Col
                        key={r.title}
                        className={r.status ? "" : "disabled-column"}
                      >
                        <div className="d-flex flex-row gap-2 checkbox-text">
                          <Form.Check
                            disabled={!r.status}
                            className="table-column-checkbox"
                            checked={allChecked}
                            onChange={() =>
                              handleAllModulesChange(r.title, sectionKey)
                            }
                          />
                          All Modules
                        </div>
                      </Col>
                    );
                  })}
                </Row>

                {/* BODY: submodules => checkboxes for each role */}
                <Accordion.Body className={`py-0 px-0 ${bodyClass}`}>
                  <Row className="px-0 mx-0">
                    <Col />
                    {module.map((r) => (
                      <Col
                        key={r.title}
                        className={r.status ? "" : "disabled-column"}
                      >
                        <Stack direction="vertical" gap={2}>
                          {sectionModules[sectionKey].map((subModule, idx) => {
                            const checkedVal =
                              permissions[r.title][sectionKey].modules[
                                subModule
                              ];
                            return (
                              <div
                                className="d-flex flex-row gap-2 checkbox-text"
                                key={idx}
                              >
                                <Form.Check
                                  disabled={!r.status}
                                  className="table-column-checkbox"
                                  checked={checkedVal}
                                  onChange={() =>
                                    handleSubModuleChange(
                                      r.title,
                                      sectionKey,
                                      subModule
                                    )
                                  }
                                />
                                {subModule}
                              </div>
                            );
                          })}
                          <div className="body-extra"></div>
                        </Stack>
                      </Col>
                    ))}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Row>

      {/* Footer: role name input & buttons */}
      <Row className="my-3">
        <Col className="p-0 m-0">
          {/* <div className="d-flex justify-content-left align-items-center gap-2">
            <input className="role-input" type="text" placeholder="Role Name" />
            <button className="role-button">Save as New Role</button>
          </div> */}
        </Col>
        <Col className="p-0 m-0">
          <div className="d-flex justify-content-end align-items-center gap-2">
            <button className="role-button px-4">Reset</button>
            <button className="role-button px-4">Save</button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Roles;
