import React, { useState, useEffect, useMemo, useRef, useContext  } from "react";
// Import MessageContext if needed for feedback
import { MessageContext } from "../../App"; // Adjust the path as necessary
// Importing icons
import updownIcon from "../../assets/updown_icon.png";
import filterIcon from "../../assets/filter-icon1.svg";
import settingIcon from "../../assets/Setting-icon.svg";
import searchIcon from "../../assets/search-icon.svg";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import { Col, Form, Modal, OverlayTrigger, Popover, Row, Stack, Tooltip } from "react-bootstrap";
import Paginationcontrol from "../entity/PaginationControl";

function CatalogTable({ stateChanger }) {
    // Inline Styles
    function Styles() {
        return (
          <style>
            {`
              .modleHeader {
                color: rgb(22, 125, 176);
                font-family: "Open Sans";
                font-weight: bold;
                font-size: 20px;
              }
              .modelTitle {
                color: #262626;
                font-family: "Open Sans";
                font-size: 16px;
              }
              .catalog-input-control {
                width: 150px;
              }
              .form-check-input:checked {
                background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path fill="%23167db0" d="M9 11.93l-3.47-3.47L4.1 10.88l5.9 5.9L20.48 6.29l-1.41-1.41L9 11.93z"/></svg>') no-repeat center;
              }
              .modal-button-catalog {
                background-color: #167db0;
                padding: 5px 25px;
                border-radius: 3px;
                border: none;
                color: white;
                cursor: pointer;
              }
              .modal-button-catalog:hover {
                background-color: #145a86;
              }
              .enable-icon {
                color: green;
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
              .disable-icon {
                color: red;
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
              .search-input {
                padding-left: 35px;
              }
              .search-icon {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
              }
              .add-entity-button {
                background-color: #167db0;
                color: white;
                border: none;
                padding: 8px 16px;
                border-radius: 4px;
                cursor: pointer;
              }
              .add-entity-button:hover {
                background-color: #145a86;
              }
              .highlight-row {
                background-color: #e6f7ff;
              }
              .delete-column {
                width: 100px;
              }
            `}
          </style>
        );
      }

    const searchInputRef = useRef(null); // Create a ref for the search bar
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility
    const [selectedColumns, setSelectedColumns] = useState({

        catalogId: true,
        make: true,
        deviceType: true,
        targetFields: true,
        sourceFields: true,
        summaryFields: true,
        aggregates: true,
        deviceVersion: true,
        deviceModel: true,
    });
    const [selectedFilter, setSelectedFilter] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
    const [tableProps, setTableProps] = useState({
        rowsPerPage: 5,
        currentPage: 1,
        pageFirst: 1,
        pageLast: 5,
    });

    const entitySearchRef = useRef(null);
    useEffect(() => {
        if (entitySearchRef.current && selectedFilter !== "") {
            entitySearchRef.current.focus();
        }
    }, [selectedFilter])

    // New state to toggle disabled catalogs
  const [disableBox, setDisableBox] = useState(false);
  const [enable, setEnable] = useState(false);
  const onMessageChange = useContext(MessageContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal visibility state
  const [selectedCatalog, setSelectedCatalog] = useState(null); // Selected catalog for confirmation


 // Retrieve userData and authToken from session storage
 const userData = JSON.parse(sessionStorage.getItem("userData"));
 const authToken = sessionStorage.getItem("authToken");
 const userEmail = userData?.email || "";
//  console.log("User email from session storage:", userEmail);

 // Declare entityId from userData (ensure it is defined)
 const entityId = userData?.entityId || "";
//  console.log("Entity ID from session storage:", entityId);

 // State for the catalog table data
 const [data, setData] = useState([]);

    const [targetFields, setTargetFields] = useState(false);
    const [sourceFields, setSourceFields] = useState(false);
    const [summaryFields, setSummaryFields] = useState(false);
    const [aggregates, setAggregates] = useState(false);
    const [deviceVersion, setDeviceVersion] = useState(false);
    const [deviceModel, setDeviceModel] = useState(false);
    const [newCatalog, setNewCatalog] = useState(false);

  // We'll store the new Catalog's data here
  const [newCatalogData, setNewCatalogData] = useState({
    catalogId: "",
    make: "",
    deviceType: "",
    deviceVersion: "",
    deviceModel: "",
  });

      // State for fetched devices and makes
  const [devices, setDevices] = useState([]);
  const [makeData, setMakeData] = useState([]);

  // API Integration for Device Types
  useEffect(() => {
    const fetchDeviceTypes = async () => {
      try {
        const response = await fetch(
          "https://solar-api.antsai.in/api/plant/getDeviceTypes",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`
            },
          }
        );
        // console.log("Device Types Response Status:", response.status);
        if (response.ok) {
          const respData = await response.json();
          // console.log("Raw Device Types Data:", respData);
          // Map response keys accordingly
          const formattedDevices = respData.map((item) => ({
            device_id: item["Device Type ID"],
            device_name: item["Device Name"],
          }));
          // console.log("Formatted Device Types:", formattedDevices);
          setDevices(formattedDevices);
        } else {
          console.error("Failed to fetch device types. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching device types:", error);
      }
    };
    fetchDeviceTypes();
  }, []);

  // API Integration for Makes
  useEffect(() => {
    const fetchMakes = async () => {
      try {
        const response = await fetch(
          "https://solar-api.antsai.in/api/plant/fetchMakes",
          {
            method: "GET",
            headers: { "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
             },
          }
        );
        // console.log("Makes Response Status:", response.status);
        if (response.ok) {
          const respData = await response.json();
          // console.log("Raw Makes Data:", respData);
          const formattedMakes = respData.map((item) => ({
            make_id: item["make_id"],
            make_name: item["make_name"],
          }));
          // console.log("Formatted Makes:", formattedMakes);
          setMakeData(formattedMakes);
        } else {
          console.error("Failed to fetch make data. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching makes:", error);
      }
    };
    fetchMakes();
  }, []);

    const handleCellClick = (key, row) => {
      // console.log("Row selected:", row);
      setSelectedCatalog(row);
        if (key === 'targetFields') {
            setTargetFields(true)
        } else if (key === 'sourceFields') {
          setTargetFields(true)
        } else if (key === 'summaryFields') {
            setSummaryFields(true)
        } else if (key === 'aggregates') {
          setSummaryFields(true)
        } else if (key === 'deviceVersion') {
            setDeviceVersion(true)
        } else if (key === 'deviceModel') {
          setDeviceVersion(true)
        }
    }

  // API Integration for fetching catalog list using POST method.
 // Fetch Catalog List (active or disabled based on disableBox)
 useEffect(() => {
    const fetchCatalogList = async () => {
      try {
        let url = "https://solar-api.antsai.in/api/fetchCatalog"; // Active catalogs endpoint
        if (disableBox) {
          url = "https://solar-api.antsai.in/api/fetchDisabledCatalog"; // Disabled catalogs endpoint
        }
        // console.log("Fetching catalog list from URL:", url);
        // console.log("Passing entityid in body:", entityId);
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ entityid: entityId }),
        });
        // console.log("Catalog List Response Status:", response.status);
        if (response.status!==200){
        setData([])
        }
        else
        if (response.ok) {
          const catalogData = await response.json();
          // console.log("Raw Catalog Data:", catalogData);
          // Map catalog data (ignoring delete_flag)
          const filteredData = catalogData.map((item) => ({
            catalogId: item.catalogId,
            make: item.make,
            deviceType: item.deviceType,
            targetFields: item.targetFields,
            sourceFields: item.sourceFields,
            summaryFields: item.summaryFields,
            aggregates: item.aggregates,
            deviceVersion: item.deviceVersion,
            deviceModel: item.deviceModel,
          }));
          // console.log("Mapped Catalog Data:", filteredData);
          setData(filteredData);
        } else {
          console.error("Failed to fetch catalog list. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching catalog list:", error);
      }
    };
    // Depend on authToken, entityId, and disableBox so re-fetch if they change.
    fetchCatalogList();
  }, [disableBox]);


    const handleSort = (column) => {
        let direction = "ascending";
        if (sortConfig.key === column && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key: column, direction });
    };

    const sortedData = useMemo(() => {
        let sortableData = [...data];
        if (sortConfig.key) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const filteredData = useMemo(() => {
        return sortedData.filter((row) => {
            if (!selectedFilter || !searchQuery) return true;
            return row[selectedFilter]
                .toLowerCase()
                .startsWith(searchQuery.toLowerCase());
        });
    }, [sortedData, selectedFilter, searchQuery]);


    const tablePropsChange = (updatedProps) => {
        setTableProps(updatedProps);
    };

    const onRowChoose = (e, row) => {
        // console.log('data delete')
    };


    // Pagination logic
    const currentRows = useMemo(() => {
        if (filteredData.length > 0) {
            return filteredData.slice(
                (tableProps.currentPage - 1) * tableProps.rowsPerPage,
                (tableProps.currentPage - 1) * tableProps.rowsPerPage +
                tableProps.rowsPerPage
            );
        }
        return [];
    }, [filteredData, tableProps]);

    const handleColumnSelect = (column) => {
        setSelectedColumns((prev) => ({ ...prev, [column]: !prev[column] }));
    };

    // Handle filter selection
    const handleFilterSelect = (column) => {
        setSelectedFilter(column); // Set the selected filter
        setIsDropdownOpen(false); // Close the dropdown
        setTimeout(() => {
            if (searchInputRef.current) {
                searchInputRef.current.focus(); // Focus on the search bar
            }
        }, 0); // Ensure UI updates before focusing
    };

const disableRow = (e, catalog) => {
    e.stopPropagation();
    setEnable(false);
    setSelectedCatalog(catalog); // Store selected catalog for confirmation
    setShowConfirmModal(true); // Show confirmation modal
  };

  // Add enableRow function to handle enabling catalog
  const enableRow = (e, catalog) => {
    e.stopPropagation();
    setEnable(true);
    setSelectedCatalog(catalog);
    setShowConfirmModal(true);
  };

  const confirmDisable = async () => {
    try {
      const response = await fetch(
        "https://solar-api.antsai.in/api/disableCatalog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({ catalogId: selectedCatalog.catalogId }),
        }
      );

      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: "catalog disabled successfully.",
        });
        setShowConfirmModal(false); // Close the modal on success
        // fetchData(); // Fetch updated data instead of reloading the page
        setTimeout(() => {
          window.location.reload();
        }, 1000); // 1-second delay
      } else {
        const errorData = await response.json();
        console.error("Failed to disable catalog:", errorData);
        onMessageChange({
          type: "Danger",
          info: `Failed to disable catalog: ${
            errorData.message || "Unknown error"
          }`,
        });
        setShowConfirmModal(false); // Close the modal in case of error
      }
    } catch (error) {
      console.error("Error disabling catalog:", error);
      onMessageChange({
        type: "Warning",
        info: "An error occurred while disabling the catalog. Please try again later.",
      });
      setShowConfirmModal(false); // Close the modal in case of error
    }
  };

  const confirmEnable = async () => {
    try {
      const response = await fetch(
        "https://solar-api.antsai.in/api/enableCatalog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({ catalogId: selectedCatalog.catalogId }),
        }
      );

      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: "catalog enabled successfully.",
        });
        setShowConfirmModal(false);
        // fetchData();
        setTimeout(() => {
          window.location.reload();
        }, 1000); // 1-second delay
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to enable catalog: ${
            errorData.message || "Unknown error"
          }`,
        });
        setShowConfirmModal(false);
      }
    } catch (error) {
      onMessageChange({
        type: "Warning",
        info: "An error occurred while enabling the catalog. Please try again later.",
      });
      setShowConfirmModal(false);
    }
  };


    /**
   *  Handle "Add New Catalog" functionalities
   */
  // 1) When user clicks "Add New" button, set newCatalog to true => show modal.
  //    Then fetch a new Catalog ID from `generateCatalogId` API.
 // 2) useEffect triggers when newCatalog === true.
//    We fetch from generateCatalogId and store the result in newCatalogData.catalogId.
// --- useEffect to fetch generated Catalog ID ---
useEffect(() => {
    const fetchCatalogId = async () => {
      if (newCatalog) {
        try {
          const response = await fetch(
            "https://solar-api.antsai.in/api/generateCatalogId",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
              },
              // For example, entityid is passed like below:
              body: JSON.stringify({ entityid: entityId }), // e.g. "SUMANTH-1000"
            }
          );
          if (response.ok) {
            const data = await response.json();
            // Notice: the response field is "catalog_id"
            setNewCatalogData((prev) => ({
              ...prev,
              catalogId: data.catalog_id || "",
            }));
          } else {
            const errorData = await response.json();
            onMessageChange({
              type: "Danger",
              info: `Failed to generate Catalog ID: ${
                errorData.message || "Unknown error"
              }`,
            });
          }
        } catch (error) {
          console.error("Error generating Catalog ID:", error);
          onMessageChange({
            type: "Warning",
            info: "An error occurred while generating the Catalog ID.",
          });
        }
      } else {
        // Reset form data when modal closes
        setNewCatalogData({
          catalogId: "",
          make: "",
          deviceType: "",
          deviceVersion: "",
          deviceModel: "",
        });
      }
    };
    fetchCatalogId();
  }, [newCatalog, authToken, entityId, onMessageChange]);

  // 2) Handle form changes for newCatalogData
  const handleNewCatalogChange = (key, value) => {
    setNewCatalogData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // 3) Save New Catalog => call addNewCatalog API
  const handleSaveNewCatalog = async () => {
    try {
      // Prepare body for "addNewCatalog" API
      // { catalogId, entityid, make, deviceType, deviceVersion, deviceModel }
      const bodyData = {
        catalogId: newCatalogData.catalogId,
        entityid: entityId,
        make: newCatalogData.make,
        deviceType: newCatalogData.deviceType,
        deviceVersion: newCatalogData.deviceVersion,
        deviceModel: newCatalogData.deviceModel,
      };

      const response = await fetch(
        "https://solar-api.antsai.in/api/addNewCatalog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(bodyData),
        }
      );

      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: "New Catalog added successfully.",
        });
        setNewCatalog(false); // Close modal
        // Optionally, reload or fetch updated catalog data:
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to add new catalog: ${
            errorData.message || "Unknown error"
          }`,
        });
      }
    } catch (error) {
      console.error("Error adding new catalog:", error);
      onMessageChange({
        type: "Warning",
        info: "An error occurred while adding the new catalog.",
      });
    }
  };

  {/* Target and Source Fields*/}
  // --- Target and Source Fields States and API Integration ---
  const [targetData, setTargetData] = useState({
    header: "",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    field6: "",
  });
  const [sourceData, setSourceData] = useState({
    header: "",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    field6: "",
  });
  // This state holds the header options for the target header dropdown.
  const [headerOptions, setHeaderOptions] = useState([]);

  // Fetch header options (GET request)
  useEffect(() => {
    const fetchHeaders = async () => {
      try {
        const response = await fetch("https://solar-api.antsai.in/api/fetchHeaders", {
          method: "GET",
        });
        if (response.ok) {
          const headersData = await response.json(); // e.g., ["DC VOLTAGE", "DC CURRENT", ...]
          setHeaderOptions(headersData);
        } else {
          console.error("Failed to fetch header options.");
        }
      } catch (error) {
        console.error("Error fetching header options:", error);
      }
    };
    fetchHeaders();
  }, []);

  // When the user selects a target header, fetch its fields.
  useEffect(() => {
    if (targetData.header) {
      const fetchFields = async () => {
        try {
          const response = await fetch("https://solar-api.antsai.in/api/fetchFields", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ header: targetData.header }),
          });
          if (response.ok) {
            const data = await response.json();
            const fields = data.fields || [];
            setTargetData((prev) => ({
              ...prev,
              field1: fields[0] || "",
              field2: fields[1] || "",
              field3: fields[2] || "",
              field4: fields[3] || "",
              field5: fields[4] || "",
              field6: fields[5] || "",
            }));
          } else {
            console.error("Failed to fetch fields for header:", targetData.header);
          }
        } catch (error) {
          console.error("Error fetching fields:", error);
        }
      };
      fetchFields();
    }
  }, [targetData.header]);

  // Function to save both target and source fields.
  const handleSaveFields = async () => {
    // console.log("Saving for catalog:", selectedCatalog);
    if (!selectedCatalog) {
      onMessageChange({
        type: "Danger",
        info: "No catalog selected.",
      });
      return;
    }

    // Convert target fields to comma-separated string
    const targetFieldsCSV = [
      targetData.field1,
      targetData.field2,
      targetData.field3,
      targetData.field4,
      targetData.field5,
      targetData.field6,
    ]
      .filter((field) => field) // Remove empty strings
      .join(",");

    // Convert source fields to comma-separated string
    const sourceFieldsCSV = [
      sourceData.field1,
      sourceData.field2,
      sourceData.field3,
      sourceData.field4,
      sourceData.field5,
      sourceData.field6,
    ]
      .filter((field) => field) // Remove empty strings
      .join(",");

    const payload = {
      catalogId: selectedCatalog.catalogId,
      targetHeader: targetData.header,
      targetfields: targetFieldsCSV,
      sourceHeader: sourceData.header,
      sourceFields: sourceFieldsCSV,
    };

    try {
      const response = await fetch("https://solar-api.antsai.in/api/addTargetSource", {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${authToken}` },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        // console.log("Fields saved successfully",payload);
        setTargetFields(false);
        onMessageChange({ type: "Success", info: "Fields saved successfully." });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const errorData = await response.json();
        console.error("Failed to save fields", errorData);
        onMessageChange({ type: "Danger", info: `Failed to save fields: ${errorData.message || "Unknown error."}` });
      }
    } catch (error) {
      console.error("Error saving fields:", error);
      onMessageChange({ type: "Warning", info: "Error saving fields." });
    }
  };

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="main-container">
                <Row className="d-flex flex-row align-items-center justify-content-between my-4">
                    <Col>
                        <span className="downloadLink" >
                            Catalog
                        </span>
                    </Col>
                    <Col className="gap-3 d-flex flex-row align-items-center justify-content-end" >

                  {/* Filter Popover (without the disable checkbox) */}
                        <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="left-start"
                        show={isDropdownOpen}
                        onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                        overlay={
                            <Popover>
                            <Popover.Body className="overlay-body-menu">
                                <ul>
                                {Object.keys(selectedColumns)
                                    .filter((key) => selectedColumns[key])
                                    .map((column) => (
                                    <li key={column} onClick={() => handleFilterSelect(column)}>
                                        {column.charAt(0).toUpperCase() + column.slice(1)}
                                    </li>
                                    ))}
                                </ul>
                            </Popover.Body>
                            </Popover>
                        }
                        >
                        <img
                            src={filterIcon}
                            alt="filter"
                            className="filter-icon"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        />
                        </OverlayTrigger>
                        <div style={{ position: 'relative' }} className=" w-auto">
                            <input
                                //  ref={searchInputRef}
                                ref={entitySearchRef}
                                id="entity_search"
                                type="text"
                                style={{
                                    width: '341px',
                                    height: '39px',
                                    background: '#BBCCD9 0% 0% no-repeat padding-box',
                                    borderRadius: '5px',
                                    opacity: ' 0.51'
                                }}
                                className="search-input"
                                placeholder={`Search by ${selectedFilter || "..."}`}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                disabled={!selectedFilter}
                            />
                            <img src={searchIcon} alt="search" className="search-icon" />
                        </div>
                        <button className="add-entity-button" onClick={() => setNewCatalog(true)}>
                        Add New
                        </button>
                    </Col>
                </Row>
                <div className="border rounded border-light-subtle">
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    {Object.keys(selectedColumns)
                                        .filter((key) => selectedColumns[key])
                                        .map((column) => (
                                            <th key={column} onClick={() => handleSort(column)}>
                                                {column.replace(/([A-Z])/g, " $1").replace(/^\w/, (c) => c.toUpperCase())}
                                                <img
                                                    src={updownIcon}
                                                    alt="sort"
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </th>
                                        ))}
                                    <th>
                                        <div className="gap-2 d-flex flex-row align-items-center justify-content-end">
                                           {/* Settings Popover (include disabled checkbox here) */}
                                        <OverlayTrigger
                                        rootClose
                                        trigger="click"
                                        placement="auto-start"
                                        overlay={
                                            <Popover>
                                            <Popover.Body className="overlay-body">
                                                <div>
                                                {Object.keys(selectedColumns).map((column) => (
                                                    <label key={column} style={{ display: "block" }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedColumns[column]}
                                                        onChange={() => handleColumnSelect(column)}
                                                    />{" "}
                                                    {column.charAt(0).toUpperCase() + column.slice(1)}
                                                    </label>
                                                ))}
                                                {/* Disabled Catalog Checkbox */}
                                                <label style={{ display: "block", marginTop: "10px" }}>
                                                    <input
                                                    type="checkbox"
                                                    checked={disableBox}
                                                    onChange={() => setDisableBox(!disableBox)}
                                                    />{" "}
                                                    Disabled Catalog
                                                </label>
                                                </div>
                                            </Popover.Body>
                                            </Popover>
                                        }
                                        >
                                        <div>
                                            <img src={settingIcon} alt="settings" className="setting-icon" />
                                        </div>
                                        </OverlayTrigger>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRows.length > 0 ? (
                                    currentRows.map((row) => (
                                    <tr key={row.catalogId}>
                                        {Object.keys(selectedColumns)
                                        .filter((key) => selectedColumns[key])
                                        .map((column) => (
                                            <td
                                            key={`${row.catalogId}-${column}`}
                                            onClick={() => handleCellClick(column, row)}
                                            >
                                            {row[column]}
                                            </td>
                                        ))}
                      <td className="delete-column">
                        <div className="d-flex flex-row align-items-center justify-content-end">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${row.catalogId}`}>
                                {row.isDisabled
                                  ? "Click to Enable this catalog"
                                  : "Click to Disable this catalog"}
                              </Tooltip>
                            }
                          >
                           <NotInterestedOutlinedIcon
                            onClick={(e) =>
                              disableBox
                                ? enableRow(e, row)
                                : disableRow(e, row)
                            }
                            style={{ cursor: "pointer" }}
                            className={
                              disableBox ? "enable-icon" : "disable-icon"
                            }
                          />
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                    <tr>
                      <td
                        colSpan={Object.keys(selectedColumns).length + 2}
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          fontSize: "16px",
                          color: "#666",
                        }}
                      >
                        {disableBox
                          ? "No disabled data yet."
                          : "No active records found for this Catalog."}
                      </td>
                    </tr>
                  )}
              </tbody>
                        </table>
                        {filteredData.length > 0 ? (
                            <Paginationcontrol
                                rowsCount={filteredData.length}
                                tableProps={tableProps}
                                tablePropsChange={tablePropsChange}
                                alwaysShown={true}
                            />
                        ) : null}
                    </div>
                </div>

            </div>
            <div>
                <Styles />

   {/* Confirmation Modal for Enable/Disable Catalog */}
  <Modal
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(false)}
            centered
          >
        <Modal.Header closeButton>
        <Modal.Title>{`${
            enable ? "Enable" : "Disable"
            } Catalog ?`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {`Are you sure you want to ${
            enable ? "Enable" : "Disable"
            } the Catalog ${selectedCatalog?.catalogId} ?`}
        </Modal.Body>
         <Modal.Footer>
            <button
                className="modal-button"
                onClick={() => setShowConfirmModal(false)}
                autoFocus // "No" as default focus
                >
                No
                </button>
                <button
                className="modal-button"
                onClick={() => (enable ? confirmEnable() : confirmDisable())}
                >
                Yes
                </button>
            </Modal.Footer>
      </Modal>

                {/* ----------------------- Target and Source Fields Modal ----------------------- */}
<Modal size="lg" show={targetFields} onHide={() => setTargetFields(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title className="modleHeader">
      Target and Source fields
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div>
      <Row>
        {/* Target Fields Section */}
        <Col>
          <Stack gap={2} direction="vertical">
            <Row>
              <Col md={5}>
                <div className="modelTitle fw-semibold text-end pe-4">
                  Target Fields
                </div>
              </Col>
              <Col></Col>
            </Row>
            {/* Target Header Dropdown */}
            <Form.Group controlId="targetHeader">
              <Row className="w-100 align-items-center d-flex flex-row">
                <Col md={5} className="text-end">
                  <Form.Label className="modelTitle fw-semibold">
                    Header :
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    name="targetHeader"
                    value={targetData.header}
                    onChange={(e) =>
                      setTargetData((prev) => ({
                        ...prev,
                        header: e.target.value,
                      }))
                    }
                  >
                    <option value="">Select Header</option>
                    {headerOptions.map((headerOption) => (
                      <option key={headerOption} value={headerOption}>
                        {headerOption}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>

            {/* Reference Variable Label */}
            <Form.Group>
              <Row className="w-100 align-items-center">
                <Col md={5}></Col>
                <Col>
                  <div className="modelTitle fw-semibold">
                    Reference Variable
                  </div>
                </Col>
              </Row>
            </Form.Group>

            {/* Target Field Name 1 */}
            <Form.Group controlId="targetField1">
              <Row className="w-100 align-items-center">
                <Col md={5} className="text-end">
                  <Form.Label className="modelTitle fw-semibold">
                    Field Name 1
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    name="targetField1"
                    placeholder="Enter Details"
                    value={targetData.field1}
                    onChange={(e) =>
                      setTargetData((prev) => ({
                        ...prev,
                        field1: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            {/* Target Field Name 2 */}
            <Form.Group controlId="targetField2">
              <Row className="w-100 align-items-center">
                <Col md={5} className="text-end">
                  <Form.Label className="modelTitle fw-semibold">
                    Field Name 2
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    name="targetField2"
                    placeholder="Enter Details"
                    value={targetData.field2}
                    onChange={(e) =>
                      setTargetData((prev) => ({
                        ...prev,
                        field2: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            {/* Target Field Name 3 */}
            <Form.Group controlId="targetField3">
              <Row className="w-100 align-items-center justify-content-end">
                <Col md={5} className="text-end">
                  <Form.Label className="modelTitle fw-semibold pe-0 me-0">
                    Field Name 3
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    name="targetField3"
                    placeholder="Enter Details"
                    value={targetData.field3}
                    onChange={(e) =>
                      setTargetData((prev) => ({
                        ...prev,
                        field3: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            {/* Target Field Name 4 */}
            <Form.Group controlId="targetField4">
              <Row className="w-100 align-items-center justify-content-end">
                <Col md={5} className="text-end">
                  <Form.Label className="modelTitle fw-semibold pe-0 me-0">
                    Field Name 4
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    name="targetField4"
                    placeholder="Enter Details"
                    value={targetData.field4}
                    onChange={(e) =>
                      setTargetData((prev) => ({
                        ...prev,
                        field4: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            {/* Target Field Name 5 */}
            <Form.Group controlId="targetField5">
              <Row className="w-100 align-items-center justify-content-end">
                <Col md={5} className="text-end">
                  <Form.Label className="modelTitle fw-semibold pe-0 me-0">
                    Field Name 5
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    name="targetField5"
                    placeholder="Enter Details"
                    value={targetData.field5}
                    onChange={(e) =>
                      setTargetData((prev) => ({
                        ...prev,
                        field5: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            {/* Target Field Name 6 */}
            <Form.Group controlId="targetField6">
              <Row className="w-100 align-items-center justify-content-end">
                <Col md={5} className="text-end">
                  <Form.Label className="modelTitle fw-semibold pe-0 me-0">
                    Field Name 6
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    name="targetField6"
                    placeholder="Enter Details"
                    value={targetData.field6}
                    onChange={(e) =>
                      setTargetData((prev) => ({
                        ...prev,
                        field6: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Stack>
        </Col>

        {/* Source Fields Section */}
        <Col>
          <Stack gap={2} direction="vertical">
            <Row>
              <Col>
                <div className="modelTitle fw-semibold">Source Fields</div>
              </Col>
              <Col></Col>
            </Row>

            {/* Source Header */}
            <Form.Group controlId="sourceHeader">
              <Row className="w-100 align-items-center d-flex flex-row justify-content-left">
                <Col md={3} className="text-end">
                  <Form.Label className="modelTitle fw-semibold">
                    Header :
                  </Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Control
                    name="sourceHeader"
                    placeholder="Enter Details"
                    value={sourceData.header}
                    onChange={(e) =>
                      setSourceData((prev) => ({
                        ...prev,
                        header: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            {/* Source Field Name Label */}
            <Form.Group>
              <Row className="w-100">
                <Col>
                  <div className="modelTitle fw-semibold">
                    Source Field Name
                  </div>
                </Col>
                <Col md={5}></Col>
              </Row>
            </Form.Group>

            {/* Source Field Name 1 */}
            <Form.Group controlId="sourceField1">
              <Row className="w-100">
                <Col>
                  <Form.Control
                    name="sourceField1"
                    placeholder="Enter Details"
                    value={sourceData.field1}
                    onChange={(e) =>
                      setSourceData((prev) => ({
                        ...prev,
                        field1: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col md={5}></Col>
              </Row>
            </Form.Group>

            {/* Source Field Name 2 */}
            <Form.Group controlId="sourceField2">
              <Row className="w-100">
                <Col>
                  <Form.Control
                    name="sourceField2"
                    placeholder="Enter Details"
                    value={sourceData.field2}
                    onChange={(e) =>
                      setSourceData((prev) => ({
                        ...prev,
                        field2: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col md={5}></Col>
              </Row>
            </Form.Group>

            {/* Source Field Name 3 */}
            <Form.Group controlId="sourceField3">
              <Row className="w-100">
                <Col>
                  <Form.Control
                    name="sourceField3"
                    placeholder="Enter Details"
                    value={sourceData.field3}
                    onChange={(e) =>
                      setSourceData((prev) => ({
                        ...prev,
                        field3: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col md={5}></Col>
              </Row>
            </Form.Group>

            {/* Source Field Name 4 */}
            <Form.Group controlId="sourceField4">
              <Row className="w-100">
                <Col>
                  <Form.Control
                    name="sourceField4"
                    placeholder="Enter Details"
                    value={sourceData.field4}
                    onChange={(e) =>
                      setSourceData((prev) => ({
                        ...prev,
                        field4: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col md={5}></Col>
              </Row>
            </Form.Group>

            {/* Source Field Name 5 */}
            <Form.Group controlId="sourceField5">
              <Row className="w-100">
                <Col>
                  <Form.Control
                    name="sourceField5"
                    placeholder="Enter Details"
                    value={sourceData.field5}
                    onChange={(e) =>
                      setSourceData((prev) => ({
                        ...prev,
                        field5: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col md={5}></Col>
              </Row>
            </Form.Group>

            {/* Source Field Name 6 */}
            <Form.Group controlId="sourceField6">
              <Row className="w-100">
                <Col>
                  <Form.Control
                    name="sourceField6"
                    placeholder="Enter Details"
                    value={sourceData.field6}
                    onChange={(e) =>
                      setSourceData((prev) => ({
                        ...prev,
                        field6: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col md={5}></Col>
              </Row>
            </Form.Group>
          </Stack>
        </Col>
      </Row>
    </div>
  </Modal.Body>
  <Modal.Footer>
    <button
      className="modal-button modal-button-catalog"
      onClick={handleSaveFields}
      autoFocus
    >
      SAVE
    </button>
  </Modal.Footer>
</Modal>

                <Modal size="lg" show={summaryFields} onHide={() => setSummaryFields(false)} centered >
                    <Modal.Header closeButton>
                        <Modal.Title className="modleHeader">Summary and Aggregate fields</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row className="d-flex flex-column flex-md-row">
                                <Col md={4}>
                                    <Stack gap={2} direction="vertical">
                                        <div><Row><Col><div className="modelTitle fw-semibold text-end pe-4">Summary</div></Col><Col></Col></Row></div>

                                        <Form.Group >
                                            <div className="gap-3 w-100 align-items-center d-flex ">
                                                <div ><div className="modelTitle fw-semibold">Field Name</div> </div>
                                                <div md={6}><div className="modelTitle fw-semibold">Formula</div> </div>
                                            </div>
                                        </Form.Group>
                                        <Form.Group >
                                            <div className="gap-4 w-100 align-items-center d-flex">
                                                <div className="text-start text-nowrap">
                                                    <Form.Label className="modelTitle fw-semibold">Ac Power </Form.Label>
                                                </div>
                                                <div className="ps-1">
                                                    <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                                                </div>
                                            </div>
                                        </Form.Group>
                                        <Form.Group >
                                            <div className="gap-5 w-100 align-items-center d-flex">
                                                <div className="text-start text-nowrap">
                                                    <Form.Label className="modelTitle fw-semibold ">Energy   </Form.Label>
                                                </div>
                                                <div md={6}>
                                                    <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                                                </div>
                                            </div>
                                        </Form.Group>
                                        <Form.Group >
                                            <div className="gap-4 w-100 align-items-center d-flex">
                                                <div className="text-start text-nowrap">
                                                    <Form.Label className="modelTitle fw-semibold pe-0 me-0"> DC Power   </Form.Label>
                                                </div>
                                                <div md={6}>
                                                    <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Stack>
                                </Col>
                                <Col >
                                    <Stack gap={2} direction="vertical">
                                        <Row><Col ><div className="modelTitle fw-semibold">Aggregate</div></Col><Col></Col></Row>

                                        <Form.Group >
                                            <Row className="w-100">
                                                <Col > <div className="modelTitle fw-semibold">Aggregate Name</div></Col>
                                                <Col > <div className="modelTitle fw-semibold">Aggregate Type</div> </Col>
                                                <Col > <div className="modelTitle fw-semibold">Range</div> </Col >
                                            </Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Row className="w-100">
                                                <Col >
                                                    <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Ac Power   </Form.Label>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Sum'>Sum </option>
                                                        <option value='Average'>Average </option>
                                                        <option value='Columnative Sum'>Columnative Sum </option>
                                                    </Form.Select>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Daily'>Daily </option>
                                                        <option value='Weekly'>Weekly </option>
                                                        <option value='Montly'>Montly </option>
                                                        <option value='Yearly'>Yearly </option>
                                                        <option value='Lifetime'>Lifetime </option>
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Row className="w-100">
                                                <Col >
                                                    <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Energy   </Form.Label>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Sum'>Sum </option>
                                                        <option value='Average'>Average </option>
                                                        <option value='Columnative Sum'>Columnative Sum </option>
                                                    </Form.Select>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Daily'>Daily </option>
                                                        <option value='Weekly'>Weekly </option>
                                                        <option value='Montly'>Montly </option>
                                                        <option value='Yearly'>Yearly </option>
                                                        <option value='Lifetime'>Lifetime </option>
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Row className="w-100">
                                                <Col>
                                                    <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Dc Power   </Form.Label>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Sum'>Sum </option>
                                                        <option value='Average'>Average </option>
                                                        <option value='Columnative Sum'>Columnative Sum </option>
                                                    </Form.Select>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Daily'>Daily </option>
                                                        <option value='Weekly'>Weekly </option>
                                                        <option value='Montly'>Montly </option>
                                                        <option value='Yearly'>Yearly </option>
                                                        <option value='Lifetime'>Lifetime </option>
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-button modal-button-catalog" onClick={() => { }} autoFocus >SAVE</button>
                        {/* <button className="modal-button" onClick={() => enable ? confirmEnable() : confirmDisable()}>Yes</button> */}
                    </Modal.Footer>
                </Modal>

                <Modal size="lg" show={aggregates} onHide={() => setAggregates(false)} centered >
                    <Modal.Header closeButton>
                        <Modal.Title className="modleHeader">Summary and Aggregate fields</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row className="d-flex flex-column flex-md-row">
                                <Col md={4}>
                                    <Stack gap={2} direction="vertical">
                                        <div><Row><Col><div className="modelTitle fw-semibold text-end pe-4">Summary</div></Col><Col></Col></Row></div>

                                        <Form.Group >
                                            <div className="gap-3 w-100 align-items-center d-flex ">
                                                <div ><div className="modelTitle fw-semibold">Field Name</div> </div>
                                                <div md={6}><div className="modelTitle fw-semibold">Formula</div> </div>
                                            </div>
                                        </Form.Group>
                                        <Form.Group >
                                            <div className="gap-4 w-100 align-items-center d-flex">
                                                <div className="text-start text-nowrap">
                                                    <Form.Label className="modelTitle fw-semibold">Ac Power </Form.Label>
                                                </div>
                                                <div className="ps-1">
                                                    <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                                                </div>
                                            </div>
                                        </Form.Group>
                                        <Form.Group >
                                            <div className="gap-5 w-100 align-items-center d-flex">
                                                <div className="text-start text-nowrap">
                                                    <Form.Label className="modelTitle fw-semibold ">Energy   </Form.Label>
                                                </div>
                                                <div md={6}>
                                                    <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                                                </div>
                                            </div>
                                        </Form.Group>
                                        <Form.Group >
                                            <div className="gap-4 w-100 align-items-center d-flex">
                                                <div className="text-start text-nowrap">
                                                    <Form.Label className="modelTitle fw-semibold pe-0 me-0"> DC Power   </Form.Label>
                                                </div>
                                                <div md={6}>
                                                    <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Stack>
                                </Col>
                                <Col >
                                    <Stack gap={2} direction="vertical">
                                        <Row><Col ><div className="modelTitle fw-semibold">Aggregate</div></Col><Col></Col></Row>

                                        <Form.Group >
                                            <Row className="w-100">
                                                <Col > <div className="modelTitle fw-semibold">Aggregate Name</div></Col>
                                                <Col > <div className="modelTitle fw-semibold">Aggregate Type</div> </Col>
                                                <Col > <div className="modelTitle fw-semibold">Range</div> </Col >
                                            </Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Row className="w-100">
                                                <Col >
                                                    <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Ac Power   </Form.Label>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Sum'>Sum </option>
                                                        <option value='Average'>Average </option>
                                                        <option value='Columnative Sum'>Columnative Sum </option>
                                                    </Form.Select>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Daily'>Daily </option>
                                                        <option value='Weekly'>Weekly </option>
                                                        <option value='Montly'>Montly </option>
                                                        <option value='Yearly'>Yearly </option>
                                                        <option value='Lifetime'>Lifetime </option>
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Row className="w-100">
                                                <Col >
                                                    <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Energy   </Form.Label>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Sum'>Sum </option>
                                                        <option value='Average'>Average </option>
                                                        <option value='Columnative Sum'>Columnative Sum </option>
                                                    </Form.Select>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Daily'>Daily </option>
                                                        <option value='Weekly'>Weekly </option>
                                                        <option value='Montly'>Montly </option>
                                                        <option value='Yearly'>Yearly </option>
                                                        <option value='Lifetime'>Lifetime </option>
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Row className="w-100">
                                                <Col>
                                                    <Form.Label className="modelTitle fw-semibold pe-0 me-0"> Daily Dc Power   </Form.Label>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Sum'>Sum </option>
                                                        <option value='Average'>Average </option>
                                                        <option value='Columnative Sum'>Columnative Sum </option>
                                                    </Form.Select>
                                                </Col>
                                                <Col >
                                                    <Form.Select >
                                                        <option value='Daily'>Daily </option>
                                                        <option value='Weekly'>Weekly </option>
                                                        <option value='Montly'>Montly </option>
                                                        <option value='Yearly'>Yearly </option>
                                                        <option value='Lifetime'>Lifetime </option>
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-button modal-button-catalog" onClick={() => { }} autoFocus >SAVE</button>
                        {/* <button className="modal-button" onClick={() => enable ? confirmEnable() : confirmDisable()}>Yes</button> */}
                    </Modal.Footer>
                </Modal>

                <Modal size="lg" show={deviceVersion} onHide={() => setDeviceVersion(false)} centered >
                    <Modal.Header closeButton>
                        <Modal.Title className="modleHeader">Device Version and Model Number</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row className="d-flex flex-column flex-md-row">
                                <Col md={3}>
                                    <Stack gap={2} direction="vertical">
                                        <div><Row><Col><div className="modelTitle fw-semibold text-end text-nowrap pe-4">Device Version</div></Col><Col></Col></Row></div>
                                        <Form.Group >
                                            <div className="gap-4 w-100 align-items-center d-flex">
                                                <div className="text-start text-nowrap">
                                                    <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                                                </div>
                                                <div className="ps-1">
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Stack>
                                </Col>
                                <Col md={3}>
                                    <Stack gap={2} direction="vertical">
                                        <div><Row><Col><div className="modelTitle fw-semibold text-end text-nowrap pe-4">Model Number</div></Col><Col></Col></Row></div>
                                        <Form.Group >
                                            <div className="gap-8 w-100 align-items-center d-flex">
                                                <div className="text-start text-nowrap">
                                                    <Form.Control className="catalog-input-control" name="Catalog Details" placeholder=" Enter Details" />
                                                </div>
                                                <div className="ps-1">
                                                </div>
                                            </div>
                                        </Form.Group>                                    
                                    </Stack>
                                </Col>
                                <Col md={3}>
                                    <Stack gap={2} direction="vertical">
                                        <div><Row><Col><div className="modelTitle fw-semibold text-end pe-4">Deprecated</div></Col><Col></Col></Row></div>
                                        <Form.Group >
                                            <div className="gap-4 w-100 align-items-center d-flex">
                                                <div className="text-start text-nowrap">
                                                    <Form.Check className="mx-2" type="checkbox" >
                                                        <Form.Check.Input checked={true} onChange={() => { }} className="form-check-input" type="checkbox" />
                                                    </Form.Check>
                                                </div>
                                                <div className="ps-1">

                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row><Col></Col><Col></Col><Col> 
                        </Col></Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-button modal-button-catalog" onClick={() => { }} autoFocus >SAVE</button>
                        {/* <button className="modal-button" onClick={() => enable ? confirmEnable() : confirmDisable()}>Yes</button> */}
                    </Modal.Footer>
                </Modal>

                {/* <Modal size="lg" show={newCatalog} onHide={() => setNewCatalog(false)} centered >
                    <Modal.Header closeButton>
                        <Modal.Title className="modleHeader">New Catalog</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Row className="py-3"></Row>
                        <div>
                            <Stack gap={3} direction="vertical">
                                <Form.Group >
                                    <Row className="justify-content-center">
                                        <Col md='2'   >
                                            <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center"> Catalog ID : </Form.Label>
                                        </Col>
                                        <Col md='4'>
                                            <Form.Control className="catalog-input-control w-100" name="Catalog Details" placeholder="New Catalog ID" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                  <Row className="justify-content-center">
                    <Col md="2">
                      <Form.Label className="modelTitle fw-semibold pe-0 me-0">Make :</Form.Label>
                    </Col>
                    <Col md="4">
                      <Form.Select>
                        <option value="">Select Make</option>
                        {makeData.map((make) => (
                          <option key={make.make_id} value={make.make_id}>
                            {make.make_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row className="justify-content-center">
                    <Col md="2">
                      <Form.Label className="modelTitle fw-semibold pe-0 me-0">Device Type :</Form.Label>
                    </Col>
                    <Col md="4">
                      <Form.Select>
                        <option value="">Select Device Type</option>
                        {devices.map((device) => (
                          <option key={device.device_id} value={device.device_id}>
                            {device.device_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
                                <Form.Group >
                                    <Row className="justify-content-center">
                                        <Col md='2'   >
                                            <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center"> Device Version : </Form.Label>
                                        </Col>
                                        <Col md='4'>
                                            <Form.Control className="catalog-input-control w-100" name="Catalog Details" placeholder="Version 0.0.1" />
                                        </Col>
                                    </Row>
                                </Form.Group> <Form.Group >
                                    <Row className="justify-content-center">
                                        <Col md='2'   >
                                            <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center"> Device Model : </Form.Label>
                                        </Col>
                                        <Col md='4'>
                                            <Form.Control className="catalog-input-control w-100" name="Catalog Details" placeholder="Model Number" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Row></Row>
                                <Row className="justify-content-center">
                                    <Col md='2'></Col>
                                    <Col md='4'><div className="d-flex justify-content-end"> <button className="modal-button modal-button-catalog text-end" onClick={() => { }} autoFocus >SAVE</button></div> </Col>
                                </Row>
                            </Stack>
                        </div>
                        <div></div>
                        <Row className="py-5"></Row>
                    </Modal.Body>
                </Modal> */}
                       {/* New Catalog Modal */}
                       <Modal size="lg" show={newCatalog} onHide={() => setNewCatalog(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title className="modleHeader">New Catalog</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Row className="py-3"></Row>
    <div>
      <Stack gap={3} direction="vertical">
        {/* Catalog ID (read-only) */}
        <Form.Group>
          <Row className="justify-content-center">
            <Col md="2">
              <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center">
                Catalog ID :
              </Form.Label>
            </Col>
            <Col md="4">
              <Form.Control
                className="catalog-input-control w-100"
                name="CatalogId"
                placeholder="Loading..."
                value={newCatalogData.catalogId}
                onChange={() => {}}
                readOnly
              />
            </Col>
          </Row>
        </Form.Group>

                {/* Make */}
                <Form.Group>
                  <Row className="justify-content-center">
                    <Col md="2">
                      <Form.Label className="modelTitle fw-semibold pe-0 me-0">
                        Make :
                      </Form.Label>
                    </Col>
                    <Col md="4">
                      <Form.Select
                        value={newCatalogData.make}
                        onChange={(e) =>
                          handleNewCatalogChange("make", e.target.value)
                        }
                      >
                        <option value="">Select Make</option>
                        {makeData.map((make) => (
                          <option key={make.make_id} value={make.make_name}>
                            {make.make_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>

                {/* Device Type */}
                <Form.Group>
                  <Row className="justify-content-center">
                    <Col md="2">
                      <Form.Label className="modelTitle fw-semibold pe-0 me-0">
                        Device Type :
                      </Form.Label>
                    </Col>
                    <Col md="4">
                      <Form.Select
                        value={newCatalogData.deviceType}
                        onChange={(e) =>
                          handleNewCatalogChange("deviceType", e.target.value)
                        }
                      >
                        <option value="">Select Device Type</option>
                        {devices.map((device) => (
                          <option key={device.device_id} value={device.device_name}>
                            {device.device_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>

                {/* Device Version */}
                <Form.Group>
                  <Row className="justify-content-center">
                    <Col md="2">
                      <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center">
                        Device Version :
                      </Form.Label>
                    </Col>
                    <Col md="4">
                      <Form.Control
                        className="catalog-input-control w-100"
                        name="DeviceVersion"
                        placeholder="Version 0.0.1"
                        value={newCatalogData.deviceVersion}
                        onChange={(e) =>
                          handleNewCatalogChange("deviceVersion", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>

                {/* Device Model */}
                <Form.Group>
                  <Row className="justify-content-center">
                    <Col md="2">
                      <Form.Label className="modelTitle fw-semibold pe-0 me-0 text-nowrap text-center">
                        Device Model :
                      </Form.Label>
                    </Col>
                    <Col md="4">
                      <Form.Control
                        className="catalog-input-control w-100"
                        name="DeviceModel"
                        placeholder="Model Number"
                        value={newCatalogData.deviceModel}
                        onChange={(e) =>
                          handleNewCatalogChange("deviceModel", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Row className="justify-content-center">
                  <Col md="2"></Col>
                  <Col md="4">
                    <div className="d-flex justify-content-end">
                      <button
                        className="modal-button modal-button-catalog text-end"
                        onClick={handleSaveNewCatalog}
                        autoFocus
                      >
                        SAVE
                      </button>
                    </div>
                  </Col>
                </Row>
              </Stack>
            </div>
            <Row className="py-5"></Row>
          </Modal.Body>
        </Modal>
            </div>
        </div>
    );
};

export default CatalogTable