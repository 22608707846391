import React, { useEffect, useContext } from "react";
import { PermissionsContext } from "../../context/PermissionsContext";  // Adjust path if needed
import "./Entity.css";
import { Col, Container, Nav, NavItem, NavLink, Row, Stack, Tab } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Divider, } from "@mui/material";
import EntityTable from "./EntityTable";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import UserTable from "../user/UserTable";
// import SolarNavbar from "../../dashboard/navbar/SolarNavbar";
import AdminBottomHeaderBar from "../../dashboard/navbar/AdminBottomHeaderBar";
import PlantTable from "../plant/PlantTable";
import EntityAddPage from "./EntityAdd";
import EntityEditPage from "./EntityEdit";
// import AdminPlantEdit from "./../plant/PlantEdit";
import AdminPlantEdit from "./../plant/AdminPlantEdit";
import AdminPlantAdd from "./../plant/PlantAdd";
import UserAddPage from "./../user/UserAdd";
import UserEditPage from "./../user/UserEdit";
import SettingsForm from "../settings/Settings";
import Roles from "../role/Roles";
import AdminReportTable from "../report/AdminReportTable";
import AdminReportAdd from "../report/AdminReportAdd";
import CatalogTable from "../catalog/CatalogTable";

const Entity = () => {
    const navigate = useNavigate();
    const entityCount = 20;

    function addNewEntity() {
        navigate("/admin/entity/add");
    }
    function addNewPlant() {
        navigate("/admin/plant/add");
    }
    function addNewUser() {
        navigate("/admin/user");
    }

      // 1) Access Permissions context
      const { permissions } = useContext(PermissionsContext);

      // 2) Utility function to check permission
      const hasPermission = (moduleName, widgetName) => {
        if (!permissions || !permissions.modules) return false;
        const moduleArray = permissions.modules[moduleName];
        if (!moduleArray) return false;
        const widgetObj = moduleArray.find((w) => w.widget_name === widgetName);
        return widgetObj && widgetObj.permission === "yes";
      };

    const [active, setActive] = React.useState(1);
    const [rowid, setRowid] = React.useState(1);
    const [rowData, setRowData] = React.useState();
    const [tab, setTab] = React.useState('first');
    const { t } = useTranslation(['entity', 'plant', 'menu', 'user']);

    const SetView = (active, tabs) => {
        sessionStorage.setItem('screens', JSON.stringify({ 'active': active, 'screen': (tabs === undefined ? tab : tabs) }))
        setActive(active);
    };

    useEffect(() => {
        const activeScren = JSON.parse(sessionStorage.getItem('screens'))
        if (activeScren !== null) {
            setTab(activeScren.screen !== '' ? activeScren.screen : 'first');
            setActive(activeScren.active !== '' ? parseInt(activeScren.active) : 1)
        }
    }, [active])


    const SetRowIdInView = (rowid) => {
        setRowid(rowid);
    };
    const setEditRow = (rowData) => {
        setRowData(rowData);
        SetView(9);
    }

    const ActiveView = () => {

        switch (active) {
            case 1:
                return <EntityTable stateChanger={SetView} rowId={setRowid} />;
            case 2:
                return <EntityAddPage stateChanger={SetView} />;
            case 3:
                return <EntityEditPage stateChanger={SetView} rowId={rowid} />;
            case 4:
                //Plant list page
                return <PlantTable stateChanger={SetView} rowId={setRowid} />;
            case 5:
                return <AdminPlantAdd stateChanger={SetView} />;
            case 6:
                //Plant Edit
                return <AdminPlantEdit stateChanger={SetView} rowId={rowid} />;
            case 7:
                //User Table
                return <UserTable stateChanger={setEditRow} rowId={setRowid} />;
            case 8:
                //User Add
                return <UserAddPage stateChanger={SetView} />;
            case 9:
                //User Edit
                return <UserEditPage stateChanger={SetView} rowData={rowData} rowId={rowid} />;
            case 10:
                return <AdminReportTable stateChanger={SetView} />
            case 11:
                //report add 
                return <AdminReportAdd stateChanger={SetView} />
            case 12: return <CatalogTable stateChanger={SetView} />

            case 20: return  <SettingsForm />    
            default:
                return <EntityTable stateChanger={SetView} rowId={setRowid} />;
        }
    };

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <AdminBottomHeaderBar />
            <Container className="mw-100">
                <Row>
                    <Tab.Container id="left-tabs-example p-2 m-2" activeKey={tab} defaultActiveKey={tab}>
                        <Container className="mw-100"> <Stack gap={0} direction="vertical">
                        <Nav as={"div"} variant="underline" className="flex-row tab-nav">

                        {/* Entity */}
                        {hasPermission("Admin", "Entity") && (
                        <NavItem>
                            <NavLink eventKey="first" onClick={() => SetView(1, "first")}>
                            {t("menu:entity")}
                            </NavLink>
                        </NavItem>
                        )}

                        {/* Plant */}
                        {hasPermission("Admin", "Plant") && (
                        <NavItem>
                            <NavLink eventKey="second" onClick={() => SetView(4, "second")}>
                            {t("menu:plant")}
                            </NavLink>
                        </NavItem>
                        )}

                        {/* Role */}
                        {hasPermission("Admin", "Role") && (
                        <NavItem>
                            <NavLink eventKey="third" onClick={() => SetView(3, "third")}>
                            {t("menu:role")}
                            </NavLink>
                        </NavItem>
                        )}

                        {/* User */}
                        {hasPermission("Admin", "User") && (
                        <NavItem>
                            <NavLink eventKey="fourth" onClick={() => SetView(7, "fourth")}>
                            {t("menu:user")}
                            </NavLink>
                        </NavItem>
                        )}

{hasPermission("Admin", "Report Templates") && (
    <NavItem>
        <NavLink eventKey="fifth" onClick={() => SetView(10, "fifth")}>
            {t("menu:report.template")}
        </NavLink>
    </NavItem>
)}

                        {/* Catalog */}
                        {hasPermission("Admin", "Catalog") && (
                        <NavItem>
                            <NavLink eventKey="sixth" onClick={() => SetView(12, "sixth")}>
                            {"Catalog"}
                            </NavLink>
                        </NavItem>
                        )}

                        {/* Settings */}
                        {hasPermission("Admin", "Settings") && (
                        <NavItem>
                            <NavLink eventKey="seventh" onClick={() => SetView(20, "seventh")}>
                            {t("menu:setting")}
                            </NavLink>
                        </NavItem>
                        )}

                        </Nav>
                            <Divider className="bg-black" />
                            <Tab.Content className="mt-2 pt-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Tab.Pane eventKey="first">
                                        {(active == 1) &&
                                            <Container as={"div"} fluid className="mw-100">
                                                <Row className="mt-2 ml-1 pt-2 align-items-center">
                                                    <Col className="px-0">
                                                        <div>
                                                            <h1 className="header-title">
                                                                {t('entity:entity.list')}
                                                            </h1>
                                                        </div>
                                                    </Col>
                                                    <Col className="px-0">
                                                        <div className="d-flex  flex-row  align-items-center justify-content-end ">
                                                            {/* <button className="add-entity-button " onClick={() => SetView(2)}> {t('entity:entity.addnew')} </button> */}
                                                               {/* Add New Entity Button */}
                                                               {hasPermission("Admin", "Add Entity") && (
                                                                    <button className="add-entity-button" onClick={() => SetView(2)}>
                                                                        {t('entity:entity.addnew')}
                                                                    </button>
                                                                )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        }
                                        {ActiveView()}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        {(active == 4) &&
                                            <Container as={"div"} fluid className="mw-100">
                                                <Row className="mt-2 ml-1 pt-2 align-items-center">
                                                    <Col className="px-0">
                                                        <div>
                                                            <h1 className="header-title">
                                                                {t('plant:plantList')}
                                                            </h1>
                                                        </div>
                                                    </Col>
                                                    <Col className="px-0">
                                                        <div className="d-flex  flex-row  align-items-center justify-content-end ">
                                                             {/* Plant Add New Button with Permission Check */}
                                                            {hasPermission("Admin", "Add Plant") && (
                                                            <button 
                                                                className="add-plant-button" 
                                                                onClick={() => SetView(5)}
                                                            >
                                                                {t('plant:addNewPlant')}
                                                            </button>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        }
                                        {ActiveView()}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third"> <Roles /> </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        {(active == 7) &&
                                            <Container as={"div"} fluid className="mw-100">
                                                <Row className="mt-2 ml-1 pt-2 align-items-center">
                                                    <Col className="px-0">
                                                        <div >
                                                            <h1 className="header-title">
                                                                {t('user:user.list')}
                                                            </h1>
                                                        </div>
                                                    </Col>
                                                    <Col className="px-0">
                                                        <div className="d-flex  flex-row  align-items-center justify-content-end ">
                                                            {/* User Add New Button with Permission Check */}
                                                            {hasPermission("Admin", "Add User") && (
                                                            <button 
                                                                className="add-entity-button" 
                                                                onClick={() => SetView(8)}
                                                            >
                                                                {t('user:user.addnew')}
                                                            </button>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        }
                                        {ActiveView()}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fifth">
                                        {ActiveView()}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="sixth">  {ActiveView()} </Tab.Pane>
                                    <Tab.Pane eventKey="seventh">{ActiveView()} </Tab.Pane>
                                </LocalizationProvider>
                            </Tab.Content>
                        </Stack>
                        </Container>
                    </Tab.Container>
                </Row>
            </Container>
        </div>
    );
};

export default Entity;