import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Modal,
} from "react-bootstrap";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import blueSolidLeftIcon from "../../assets/blue_solid_left.png";
import PlantTable from "../user/PlantTable";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { MessageContext } from "../../App";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function Styles() {
  return (
    <style>
      {`

                  .modal-button {
                      background-color: black;
                      color: white;
                      border: none;
                      padding: 10px 20px;
                      border-radius: 10px;
                      font-family: 'Open Sans', sans-serif;
                      font-weight: 600; /* Semibold weight */
                      cursor: pointer;
                      transition: background-color 0.3s, color 0.3s;
                    }

                    .modal-button:hover {
                      background-color: white;
                      color: black;
                      border: 1px solid black;
                    }
                   .row-card{
                     background-color:#f2f5f7;
                     text-align: left;
                     align-items: center;
                     justify-content-center;
                     box-shadow: 0px 0px 3px 1px #d7dadb;
                     border-radius: 5px;
                   }
    
                   .form-button{
                       background-color: #167db0;
                       color: white;
                       border-radius: 7px;
                       padding: 3px 20px 3px 20px
                    }
                   .verify-message{
                        color: #3767A2;
                        font-weight:700px;
                        font-size:16px;
                        padding-left:13px;  
                        letter-spacing: 1px;
                    }
    
                    .fw-bold{
                        font-weight:700px;
                        font-size:22px;
                        letter-spacing: 2px;
                        align-items: center;
                    }
    
                    .form-button:hover{
                    background-color: white !important;
                    color : black !important;
                    border-color:#167db0 !important;
                    }
    
                    .form-button-sp{
                        padding: 1px 10px 1px 10px !important;
                        border-radius: 20px !important;
                    }
    
                  .card-height{
                   height: 88% !important
                  }   
                   
                   .user-add-form{
                        font-family: 'Open Sans';
                        font-weight: normal;
                    } 
                    .user-add-form .row{
                    align-items: center;
                    }
                    .user-add-form .form-label{
                     font-weight:bold;
                     margin: 0px;
                    }
                     .user-add-form .form-select{
                     font-weight:normal !important;
                     font-family: 'Open Sans';
                    }
    
                    .user-add-form .form-button{
                      font-family: 'Open Sans';
                        font-weight: bold;
                    }
                   .user-add-form .form-control{
                      font-family: 'Open Sans';
                        font-weight: normal;
                    }
                   .user-add-form .form-check-input{
                       box-shadow: 0px 1px 3px #00000029;
                       border: 1px solid #167db0;
                       border-radius: 5px;
                       opacity: 1;
                       width: 20px;
                       height: 20px;
    
                   }
             .user-form-check-radio{
               border-radius:10px !important;
               }   
              
                  .user-add-form .form-check-input:checked{
                  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path fill="%23167db0" d="M9 11.93l-3.47-3.47L4.1 10.88l5.9 5.9L20.48 6.29l-1.41-1.41L9 11.93z"/></svg>') no-repeat center;
                  }     
                  .entity-form-left-arrow{
                    width: 23px;
                    height: 23px;
                    opacity: 1;
                    margin-right: 5px;
                   }
                    .user-title{
                    font-family: 'Open Sans';
                    font-weight: bold;
                    font-size: 20px;
                    color: #0F0D0D;
                    }

                      .PhoneInputInput{
               border:none !important;
               } 
               .PhoneInput{
                   width: 100%;
                   padding: .375rem .75rem;
                   font-size: 1rem;
                   font-weight: 400;
                   line-height: 1.5;
                   appearance: none;
                   background-color: var(--bs-body-bg);
                   background-clip: padding-box;
                   border: var(--bs-border-width) solid var(--bs-border-color);
                   border-radius: var(--bs-border-radius);
                   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
               }

             .PhoneInput--focus{
             border:0px !important;
                 border:none !important;
                 }
            `}
    </style>
  );
}

function UserEditPage({ stateChanger, rowData }) {
  const [userRoles, setUserRoles] = useState([]);
  const { t } = useTranslation("user");
  const [validated, setValidated] = useState(false);
  const onMessageChange = useContext(MessageContext);
  const [errors, setErrors] = useState({});
  const [showPlantModal, setShowPlantModal] = useState(false); // State to control the modal
  const [formData, setFormData] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNo: "",
    role: "", // Ensure this matches the role ID or name from the API
    disableUser: false,
    userType: "",
    plantIds: [],
    entityId:
      rowData?.entityId ||
      JSON.parse(sessionStorage.getItem("userData"))?.entityId ||
      "", // Initialize entityId here
  });
  const [userUpdate, setUserUpdate] = useState(true);
  const [rolesUpdate, setRolesUpdate] = useState(true);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const entityId = userData?.entityId || ""; // Extract entityId from session storage
  const authToken = sessionStorage.getItem("authToken");

   // Flag to check if the logged-in user is a plant_user.
   const isPlantUserLoggedIn =
   userData && userData.user_type === "plant_user";

  const [selectedPlantIds, setSelectedPlantIds] = useState([]); // Track selected plants

  const [uData, setUData] = useState();
  useEffect(() => {
    if (rowData === undefined) {
      const udata = JSON.parse(sessionStorage.getItem('udata'));
      if (udata === null || udata === undefined) {
        stateChanger(7)
      } else {
        setUData(udata)

      }
    } else {
      sessionStorage.setItem('udata', JSON.stringify(rowData))
      setUData(rowData)
    }
  }, [rowData])

  const handlePlantSelection = (plantIds) => {
    // console.log("Selected Plant IDs in UserAddPage:", plantIds);
    setFormData((prevData) => ({
      ...prevData,
      plantIds: plantIds, // Set plantIds in formData directly
    }));
  };

  useEffect(() => {
    // console.log("Received rowData in UserEditPage:", uData);

    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `https://solar-api.antsai.in/api/user/getUserDetails`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            params: {
              userid: uData.userId,
              entityid: uData.entityId || formData.entityId,
            },
          }
        );
        if (response.status === 200) {
          const userData = response.data;
          // console.log("Fetched user data:", userData);

          // Map the response to match formData keys
          setFormData({
            userId: uData.userId,
            firstName: userData.firstName,
            lastName: userData.lastName,
            emailId: userData.email,
            mobileNo: userData.mobileNo,
            role: userData.role,
            disableUser: userData.disableUser,
            plantIds: userData.plantIds || [],
            userType: userData.user_type, // Dynamically set userType
            entityId: uData.entityId || formData.entityId, // Ensure entityId is set correctly
          });
        } else {
          console.error("Failed to fetch user details");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    if (uData !== undefined && userUpdate) {
      setUserUpdate(false)
      fetchUserDetails();

    }
  }, [uData]);

  useEffect(() => {
    // Fetch roles from backend and log data structure
    if (uData !== undefined && rolesUpdate) {
      setRolesUpdate(false);
      fetch("https://solar-api.antsai.in/api/user/getUserRoles",{
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => response.json())
        .then((data) => {
          // console.log("Fetched user roles:", data); 
          setUserRoles(data);
        })
        .catch((error) => console.error("Error fetching user roles:", error));
    }

  }, [uData]);

  const handleChange = (event) => {
    const target = event.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.plantIds.length === 0) {
      setShowPlantModal(true); // Show modal if plantIds is empty
      return;
    }
    submitForm(); // Proceed if plantIds is not empty
  };

  const submitForm = async () => {
    const {
      userId,
      entityId,
      firstName,
      lastName,
      mobileNo,
      role,
      disableUser,
      plantIds,
      userType,
    } = formData;
    const payload = {
      userid: userId,
      entityid: entityId,
      firstName,
      lastName,
      mobileNo,
      role,
      disableUser,
      plantIds,
      userType,
    };

    // console.log("Payload Data:", payload);

    try {
      const response = await fetch(
        "https://solar-api.antsai.in/api/user/updateUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include the auth token
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        onMessageChange({
          type: "Success",
          info: "User updated successfully!",
        });
        // stateChanger(10); // Redirect to UserTable page (adjust "1" as needed for correct state/page ID)
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to update user: ${errorData.message || "Unknown error"
            }`,
        });
      }
    } catch (error) {
      console.error("Error in updating user:", error);
      onMessageChange({
        type: "Danger",
        info: "An error occurred while updating the user. Please try again.",
      });
    }
  };

  const handlemobileNo = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      mobileNo: e,
    }));
  }

  const capitalizeWords = (str) => {
    return str
      .toLowerCase() // Ensure all characters are lowercase first
      .split(" ") // Split the string into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words back into a single string
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center user-add-form">
      <Container className="mw-100">
        <Styles />
        <Row className="mt-2 mb-2">
          <Col className="d-flex flex-row align-items-center justify-content-start center-block">
            <div className="d-flex align-items-center">
              <img
                className="entity-form-left-arrow"
                onClick={() => stateChanger(7)}
                src={blueSolidLeftIcon}
                alt="sort"
                style={{ cursor: "pointer" }}
              />
              <span className="fw-bold text-decoration-none text-black">
                {t("user:user")}
              </span>
            </div>
          </Col>
          <Col className="d-flex flex-row align-items-center justify-content-end"></Col>
        </Row>
      </Container>
      <Container className="mw-100">
        <Form
          className="w-100 p-0 m-0 user-add-form"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <div className="d-flex flex-column align-items-center justify-content-center w-80 p-0 mt-2">
            <div className="w-100 text-left">
              <span className="user-title">{t("user:editUser")}</span>
            </div>
            <Row className="w-100 row-card p-4 m-2 d-flex flex-lg-row flex-column">
              <Col>
                <Stack as="row" gap={2} direction="vertical">
                  <Col>
                    <Form.Group controlId="userFirstname">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("user:firstname")}{" "}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />
                            :
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            name="firstName"
                            value={formData.firstName}
                            required
                            type="text"
                            onChange={handleChange}
                            placeholder="Enter First Name"
                            maxLength="30"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="userLastname">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("user:lastname")}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />{" "}
                            :
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            name="lastName"
                            value={formData.lastName}
                            required
                            type="text"
                            onChange={handleChange}
                            placeholder="Enter Last Name"
                            maxLength="30"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="userEmailid">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("user:emailid")}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />{" "}
                            :
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            name="emailId"
                            value={formData.emailId}
                            required
                            type="email"
                            disabled
                            onChange={handleChange}
                            placeholder="Enter e-mail id"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Stack>
              </Col>
              <Col>
                <Stack gap={3} direction="vertical">
                  <Form.Group controlId="mobileNo">
                    <Row className="w-100">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">
                          Mobile {" "}
                          <StarOutlinedIcon style={{ color: "red", fontSize: "smaller" }}/>{" "}<span>:</span>
                        </Form.Label>
                      </Col>
                      <Col md={8}>
                        <PhoneInput
                          name="mobileNo"
                          international
                          placeholder="Enter phone number"
                          value={formData.mobileNo}
                          onChange={(e) => handlemobileNo(e)}
                        />
                        <Form.Control.Feedback type="invalid">{errors.mobileNo}</Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Row className="w-100">
                    <Col md={3}>
                      <Form.Label className="text-nowrap">{'User Type'}{" "}
                        <StarOutlinedIcon style={{ color: "red", fontSize: "smaller" }}/>{" "}:
                      </Form.Label>
                    </Col>
                    <Col md={3}>
                      {/* <Form.Check className="mx-2" type="radio" >
                      <Form.Check.Input
                        checked={formData.userType === "entity_user"}
                        onChange={() =>
                          setFormData((prevData) => ({ ...prevData, userType: "entity_user" }))
                        }
                        className="user-form-check-radio"
                        type="radio"
                      />
                        <Form.Check.Label >Entity User</Form.Check.Label>
                      </Form.Check> */}
                       <Form.Check className="mx-2" type="radio">
                        <Form.Check.Input
                          checked={formData.userType === "entity_user"}
                          onChange={() =>
                            setFormData((prevData) => ({
                              ...prevData,
                              userType: "entity_user",
                            }))
                          }
                          className="user-form-check-radio"
                          type="radio"
                          // Disable the Entity User radio if the logged in user is plant_user
                          disabled={isPlantUserLoggedIn}
                        />
                        <Form.Check.Label>Entity User</Form.Check.Label>
                      </Form.Check>
                    </Col>

                    <Col md={3}>
                      <Form.Check className="mx-2" type="radio" >
                      <Form.Check.Input
        checked={formData.userType === "plant_user"}
        onChange={() =>
          setFormData((prevData) => ({ ...prevData, userType: "plant_user" }))
        }
        className="user-form-check-radio"
        type="radio"
      />
                        <Form.Check.Label >Plant User</Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                  <Form.Group controlId="userRole">
                    <Row className="w-100">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">{t("role")}{" "}
                          <StarOutlinedIcon style={{ color: "red", fontSize: "smaller" }}/>:
                        </Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Select
                          name="role"
                          value={formData.role}
                          onChange={handleChange}
                          required
                        >
                          {formData.role === "" ? (
                            <option value="">{t("selectRole")}</option>
                          ) : (
                            ""
                          )}
                          {formData.userType === 'entity_user' ? userRoles.filter(r => r.role_name !== 'individual').map((role) => (
                            <option key={role.role_id} value={role.role_name}>
                              {capitalizeWords(role.role_name)}
                            </option>
                          )) : ''}
                          {formData.userType !== 'entity_user' ? userRoles.map((role) => (
                            <option key={role.role_id} value={role.role_name}>
                              {capitalizeWords(role.role_name)}
                            </option>
                          )) : ''}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                </Stack>
              </Col>
            </Row>
            <Row className="w-100">
              <PlantTable
                onSelectPlant={handlePlantSelection}
                selectedPlantIds={formData.plantIds} // Pass plantIds as a prop
              />
            </Row>
            <Row className="w-100">
              <div className="w-100 d-flex flex-row align-items-center justify-content-end">
                <Button type="submit" className="ml-3 m-1 form-button">
                  Save User
                </Button>
              </div>
            </Row>
          </div>
        </Form>
      </Container>
      {/* Modal for empty plantIds */}
      <Modal
        show={showPlantModal}
        onHide={() => setShowPlantModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Continue Without Plants?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to add this user without any plants?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="modal-button"
            onClick={() => setShowPlantModal(false)}
          >
            No
          </button>
          <button
            className="modal-button"
            onClick={() => {
              setShowPlantModal(false);
              submitForm();
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserEditPage;
