import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as GridIcon } from "./../../assets/grid2_icon.svg";
import { ReactComponent as InfoIcon } from "./../../assets/information_icon.svg";
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import "./Production.css"; // Importing the CSS file
import { Card } from "react-bootstrap";
import { BorderColor } from "@mui/icons-material";
import dayjs from "dayjs";

// const data = [
//   { name: "Jan", value: 20 },
//   { name: "Feb", value: 40 },
//   { name: "Mar", value: 50 },
//   { name: "Apr", value: 35 },
//   { name: "May", value: 40 },
//   { name: "Jun", value: 30 },
// ];





const Production = () => {

  const [units, setUnits] = useState('KWH');
  const [unitValue, setUnitValue] = useState(1000);
  // Retrieve userData from session storage
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const token = userData?.token || "";

  // Fetch data from the API
  const [data, setData] = useState([]);

  useEffect(() => {
    // Retrieve userData and authToken from session storage
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    if (!token) {
      console.error("Auth token is missing from session storage.");
      return; // Exit if no auth token is available
    }

    // Fetch data with the token in the header
    const api = 'https://solar-api.antsai.in/api/monthlySummary';
    fetch( api , {
      method: "POST",
      headers: { 'Content-Type' : 'application/json', Authorization : `Bearer ${userData.token}` },    
      body: JSON.stringify({
        "user_id": userData.userId
      })
    }).then((response) => {
      if (!response.ok) {      
        setData([])  
        throw new Error(`HTTP error! Status: ${response.status}`);
      }else{
      return response.json();
      }
    }).then((data) => {
      // Process and format the data
      if (Array.isArray(data.summary)) {
        const formattedData = data.summary
        setData(formattedData); // Update the state with formatted data
      } else {
        console.error("Unexpected data format:", data);
        setData([]); // Handle unexpected data format
      }
    }).catch((error) => {
        console.error("Error fetching data:", error);
        setData([]); // Set to empty array on error
      });
  }, []);

  //api response
  // {
  //   "summary" : [
  //     {
  //       "plant_id": "MOHANA-P-1003",
  //       "date": "2025-01-01T00:00:00.000Z",
  //       "totalOutputEnergy": 6004.08,
  //       "online_status": 0,
  //       "type": "Hybrid",
  //       "category": "Unknown",
  //       "district": "Bangalore"
  //     },
  //   ]
  // }

  const filteredData = useMemo(() => {
    return data
      .sort((a, b) => dayjs(a.date).diff(b.date, 'month') >= 0 ? 1 : -1)
      .reduce((acc, item) => {
        let m = dayjs(item.date).format("MMM");
        let index = acc.findIndex(i => i.name === m);
        if (index === -1) {//.includes({ name: hm })) {
          acc.push({
            name: m,
            items: [item],
            energy: item.totalOutputEnergy
          })
        } else {
          acc[index].items.push(item);
          acc[index].energy = (item.totalOutputEnergy + acc[index].energy);
        }
        return acc;
      }, []);
  }, [data])

  useEffect(() => {
    const u = filteredData.findIndex((unit) =>{return unit.energy >= unitValue});

    if (u !== -1) {
      filteredData.map((unit) => unit.energy = (unit.energy / unitValue));
      setUnits('MWH')
    } else {
      setUnits('KWH')
    }
  }, [filteredData])

  return (
    <Card className=" rounded-3 shadow-sm h-95" >
      <Card.Header as={"div"} className="bg-transparent d-flex flex-row align-items-center border-bottom border-bottom-2 shadow-sm border-secondary-subtle">
        <GridIcon width={12} height={20} />
        <div className="production-title">Production</div>
        <InfoIcon width={20} height={20} style={{ border: '2px #818F96' }} />
      </Card.Header>
      <Card.Body>
        <div className="production-barchart-wrapper">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="production-left-text">{dayjs().format('YYYY')}</span>
            <div className="production-energy-text gap-1"><span className="energy-production">Energy Production </span><span> {units}</span></div>
          </div>
          
          <ResponsiveContainer width="100%" height={173}>
          {filteredData.length<=0 ? <div className="fw-bold"> No Data Available</div>:
            <RechartsBarChart data={filteredData} margin={{ top: 20, right: 30, left: 0, bottom: 20 }} >
              <XAxis dataKey="name" offset={50} fontSize={12}>
                <Label
                  value="Months"
                  offset={-15}
                  position="insideBottom"
                  fontSize={16}
                  style={{ textAnchor: "middle", color: '#1C2E38' }}
                />
              </XAxis>
              <YAxis fontSize={12} padding={{ bottom: 2 }} >
                <Label
                  value="Energy Output"
                  angle={-90}
                  position="insideLeft"
                  style={{ textAnchor: "middle", color: '#1C2E38' }}
                  fontSize={16}
                />
              </YAxis>
              <Tooltip  formatter={(v)=>`${v.toFixed(2)} ${units}`} cursor={false} />
              <Bar
                dataKey="energy"
                fill="#C5D956"
                barSize={30}
                radius={[5, 5, 0, 0]}
              />
            </RechartsBarChart>}
          </ResponsiveContainer>
        </div>
      </Card.Body>
    </Card>
    /*
      <div className="production-card">
        <div className="production-header">
          <div className="production-title-container">
          <GridIcon width={15} height={15} />
          <h3 className="production-title">Production</h3>
        </div>
        <InfoIcon width={16} height={16} />
      </div>

      <div className="production-divider"></div>

      <div className="production-barchart-wrapper">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="production-left-text">2024</span>
          <span className="production-energy-text">Energy Production MWH</span>
        </div>
        <ResponsiveContainer width="100%" height={200}>
          <RechartsBarChart
            data={data}
            margin={{ top: 20, right: 30, left: 0, bottom: 30 }}
          >
            <XAxis dataKey="name" fontSize={12}>
              <Label
                value="Months"
                offset={-15}
                position="insideBottom"
                fontSize={16}
              />
            </XAxis>
            <YAxis
              domain={[0, 50]}
              ticks={[0, 10, 20, 30, 40, 50]}
              fontSize={12}
            >
              <Label
                value="Energy Output"
                angle={-90}
                position="insideLeft"
                style={{ textAnchor: "middle" }}
                fontSize={16}
              />
            </YAxis>
            <Tooltip />
            <Bar
              dataKey="value"
              fill="#C5D956"
              barSize={30}
              radius={[5, 5, 0, 0]}
            />
          </RechartsBarChart>
        </ResponsiveContainer>
      </div>
    </div>
    */
  );
};

export default Production;
