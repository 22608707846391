import React, { useState, useEffect } from "react";
import NTFilter from "../../assets/NTFilter.svg"; // Adjust path as necessary
import pdfIcon from "../../assets/pdf_icon.svg"; // Adjust path as necessary
import AdminExit from "../../assets/exit.png"; // Adjust path as necessary
import { Col, Container, Form, InputGroup, Row, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Navbar.css";
// import { UserContext } from "../../Layout";
import { useContext } from "react";
function Styles() {
  return (
    <style>{`
            .top-search::placeholder {
                  color:white !important;
                  opacity:0.6
             }
            .overview-heading {
                  padding-left:20px;
                  font-weight:bold;
                  letter-spacing:2px;
             }
            .overview-divider {
                  padding-left:25px;
             }
                 
        `}</style>
  );
}

function AdminBottomHeaderBar() {
  // const adminRole=useContext(UserContext);
  const navigate = useNavigate(); // Initialize navigate function
  const [entityName, setEntityName] = useState("");

  // Fetch userData from sessionStorage when the component mounts
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    if (userData?.entityName) {
      setEntityName(userData.entityName);
    }
  }, []); // Empty dependency array ensures this runs only on the initial render

  const handleExitClick = () => {
    // adminRole.setAdmin(false);
    // sessionStorage.setItem('aType',false);    
    navigate("/dashboard/overview"); // Navigate to the overview page
  };

  return (
    <Container as={"div"} fluid className="mw-100 d-flex flex-column flex-lg-row align-items-sm-end align-items-center justify-content-sm-between bottom-bar">
       <h5 className="overview-heading">
        Administrator - {entityName || "Loading..."}
      </h5>
       <Styles />
      <Styles />
      <Row className="d-flex flex-sm-row flex-column align-items-center justify-content-sm-between gap-2">
        <Col>
        </Col>
        <Col className="d-flex flex-row align-items-center justify-content-between">
        <OverlayTrigger
        placement="bottom" // Tooltip position (can be "top", "bottom", "left", "right")
        overlay={<Tooltip id="tooltip-exit">Exit</Tooltip>} // Tooltip text
>
          <img
            src={AdminExit}
            alt="Exit"
            className="overview-icon"
            onClick={()=>handleExitClick()} // Add onClick handler for navigation
            style={{ cursor: "pointer", width: "36px", height: "36px" }} // Adjust size here
          />
          </OverlayTrigger>
        </Col>
      </Row>
    </Container>
  );
}
export default AdminBottomHeaderBar;