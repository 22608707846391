import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Modal from "react-modal";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import editBtn from "../../assets/editBtn.svg";
import "./Settings.css";
import { PermissionsContext } from "../../context/PermissionsContext";

// --- Mock/Static Data ---
const locationTypeData = [
  { label: "Country, Region, State/UT, District, City/Town/Village, Pin code", country: "India", selected: false },
  { label: "Country, Region, District", country: "Germany", selected: false },
];
const countryOptions = locationTypeData.map((loc) => loc.country);

// --- Success Modal ---
const SuccessModal = ({ isOpen, onClose }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="Success Modal"
    overlayClassName="settings-modal-overlay"
    className="settings-modal-content"
  >
    <div className="settings-modal-header">Success!</div>
    <div className="settings-modal-body">Data has been saved successfully.</div>
    <button className="settings-modal-close-button" onClick={onClose}>
      Close
    </button>
  </Modal>
);

const SettingsForm = () => {

 // --------------------- Get auth/session data once ---------------------
 const userData = JSON.parse(sessionStorage.getItem("userData")) || {};
 const entityId = userData.entityId || "";
 const authToken = sessionStorage.getItem("authToken") || "";

  // Access Permissions context
  const { permissions } = useContext(PermissionsContext);

  // Helper: check if user has "yes" permission for a given module/widget
  const hasPermission = (moduleName, widgetName) => {
    if (!permissions || !permissions.modules) return false;
    const moduleArr = permissions.modules[moduleName];
    if (!moduleArr) return false;
    const widgetObj = moduleArr.find((w) => w.widget_name === widgetName);
    return widgetObj && widgetObj.permission === "yes";
  };

  // Check if user can view "Settings"
  const canViewSettings = hasPermission("Admin", "Settings");
  if (!canViewSettings) {
    return (
      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <h4>You do not have permission to view Settings.</h4>
      </div>
    );
  }

  // --------------------- States ---------------------
  // Language
  const [language, setLanguage] = useState("");
  const [languageOptions, setLanguageOptions] = useState([]);

  // Location types
  const [locationTypes, setLocationTypes] = useState(locationTypeData);

  // Error Code Settings
  const [make, setMake] = useState("");
  const [makeData, setMakeData] = useState([]);
  const [file, setFile] = useState(null);

  // API Key Settings
  const [apiKeyData, setApiKeyData] = useState({
    make: "",
    country: "",
    apiKey: "",
    url: "",
    token: "",
    username: "",
    password: "",
  });

  // General
  const [errors, setErrors] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [showPassword, setShowPassword] = useState({
    username: false,
    password: false,
  });

  const [isEditMode, setIsEditMode] = useState({
    language: false,
    locationTypes: false,
    errorCodeSettings: false,
    apiKeys: false,
  });

  // --------------------- Effects ---------------------
  // Fetch list of makes on mount
  useEffect(() => {
    const fetchMakes = async () => {
      try {
        const response = await fetch("https://solar-api.antsai.in/api/plant/fetchMakes", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (response.ok) {
          const respData = await response.json();
          const formattedMakes = respData.map((item) => ({
            make_id: item.make_id,
            make_name: item.make_name,
          }));
          setMakeData(formattedMakes);
        } else {
          console.error("Failed to fetch make data. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching makes:", error);
      }
    };
    fetchMakes();
  }, []);

  // Fetch languages from API

  useEffect(() => {
    const fetchDefaultLanguages = async () => {
      try {
        if (!authToken || !entityId) {
          console.error("Missing authToken or entityId");
          return;
        }
  
        console.log("Auth Token:", authToken);
        console.log("Entity ID:", entityId);
  
        // Call the getlanguages endpoint with entityId in the path.
        const res = await axios.post(
          `https://solar-api.antsai.in/api/getlanguages/${entityId}`,
          {},
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
  
        // According to your Postman example, response is { "languages": "English" }
        if (res.data && res.data.languages) {
          // If the API returns a single string like "English"...
          // store it in state as both the selected language and the only item in languageOptions
          setLanguageOptions([res.data.languages]);
          setLanguage(res.data.languages);
        }
      } catch (error) {
        console.error("Error fetching default languages:", error);
      }
    };
  
    fetchDefaultLanguages();
  }, [entityId, authToken]);

    // 3) Fetch all available languages (additional languages)
    useEffect(() => {
      const fetchLanguages = async () => {
        try {
          console.log("Auth Token:", authToken);
    
          const res = await axios.post(
            "https://solar-api.antsai.in/api/languages",
            {},
            { headers: { Authorization: `Bearer ${authToken}` } }
          );
          const languages = res.data || [];
          // Sort them if needed
          setLanguageOptions(languages.sort());
        } catch (error) {
          console.error("Error fetching languages:", error);
        }
      };
      fetchLanguages();
    }, [authToken]);

  // --------------------- Handlers ---------------------
  const toggleEditMode = (section) => {
    setIsEditMode((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const showSuccessModal = () => setSuccessModal(true);

  // Helper: simple field check
  const validateField = (value, fieldName) =>
    value ? "" : `${fieldName} is required`;

  // --- Language ---
 // --------------------- Language ---------------------
 const handleSaveLanguage = async () => {
  if (!entityId) {
    console.error("Entity ID is missing in sessionStorage.");
    setErrors({ entityId: "Entity ID is required to save language settings." });
    return;
  }

  try {
    await axios.post(
      "https://solar-api.antsai.in/api/savelanguages",
      { entityId, languages: language },
      { headers: { Authorization: `Bearer ${authToken}` } }
    );

    // Success
    showSuccessModal();
    setIsEditMode((prev) => ({ ...prev, language: false }));
  } catch (err) {
    console.error("Error saving language:", err.response?.data || err.message);
  }
};

  // --- Location Types ---
  const toggleLocationSelection = (index) => {
    setLocationTypes((prev) =>
      prev.map((loc, i) => (i === index ? { ...loc, selected: !loc.selected } : loc))
    );
  };
  const handleSaveLocationTypes = async () => {
    const selected = locationTypes.filter((loc) => loc.selected);
    if (selected.length === 0) {
      setErrors({ locationTypes: "At least one location must be selected." });
      return;
    }
    // Example API
    // await axios.post("/api/save-location-types", { locations: selected });
    showSuccessModal();
    setIsEditMode((prev) => ({ ...prev, locationTypes: false }));
  };

  // --- Error Code Settings ---
  const handleFileChange = (e) => setFile(e.target.files[0]);

  const handleSaveErrorCodeSettings = async () => {
    const errorMake = validateField(make, "Make");
    const errorFile = file ? "" : "File is required";
    if (errorMake || errorFile) {
      setErrors({ make: errorMake, file: errorFile });
      return;
    }
    // Example API
    // const formData = new FormData();
    // formData.append("make", make);
    // formData.append("file", file);
    // await axios.post("/api/save-error-code-settings", formData);

    showSuccessModal();
    setIsEditMode((prev) => ({ ...prev, errorCodeSettings: false }));
  };

  // --- API Keys ---
  // At the top with your other states:
const [countryList, setCountryList] = useState([]);

useEffect(() => {
  const fetchCountries = async () => {
    try {
      const response = await fetch("https://solar-api.antsai.in/api/countries", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}), // Adjust if your API needs any specific payload
      });

      if (response.ok) {
        const data = await response.json();
        // data should be an array of country names or country objects
        // For example: ["India", "Germany", "USA", ...] 
        // or [{ "country_name": "India" }, { "country_name": "Germany" }, ...]

        // store the result
        setCountryList(data);
      } else {
        console.error(
          `Failed to fetch countries: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  fetchCountries();
}, []);


  const handleApiKeyInputChange = (e) => {
    const { name, value } = e.target;
    setApiKeyData((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleSaveApiKeySettings = async () => {
    const { make, country, apiKey, url, token, username, password } = apiKeyData;
    const errorMake = validateField(make, "Make");
    const errorCountry = validateField(country, "Country");
    const errorApiKey = validateField(apiKey, "API Key");
    const errorURL = validateField(url, "URL");
    const errorToken = validateField(token, "Token");
    const errorUsername = validateField(username, "Username");
    const errorPassword = validateField(password, "Password");

    if (
      errorMake ||
      errorCountry ||
      errorApiKey ||
      errorURL ||
      errorToken ||
      errorUsername ||
      errorPassword
    ) {
      setErrors({
        make: errorMake,
        country: errorCountry,
        apiKey: errorApiKey,
        url: errorURL,
        token: errorToken,
        username: errorUsername,
        password: errorPassword,
      });
      return;
    }
    // Example API
    // await axios.post("/api/save-api-keys", apiKeyData);

    showSuccessModal();
    setIsEditMode((prev) => ({ ...prev, apiKeys: false }));
  };

  // --------------------- Render ---------------------
  return (
    <div className="settings-container">
      <h2>Settings</h2>

      {/* --------------------- Language Section --------------------- */}
      <div className="settings-form-section">
        <h3>
          Language Settings :
          <img
            src={editBtn}
            alt="Edit"
            className="settings-button-edit"
            onClick={() => toggleEditMode("language")}
          />
        </h3>
        <div className="settings-form-content-box">
          <div className="settings-form-content-blocks">
            <label>
              Language <span className="settings-required">*</span>:
            </label>
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              disabled={!isEditMode.language}
            >
              <option value="">Select Language</option>
              {languageOptions.map((lang, idx) => (
                <option key={idx} value={lang}>
                  {lang}
                </option>
              ))}
            </select>
            {errors.language && <span className="settings-error">{errors.language}</span>}
          </div>
        </div>
        <div className="settings-button-container">
          <button
            className="settings-button-save"
            onClick={handleSaveLanguage}
            disabled={!isEditMode.language}
          >
            Save
          </button>
        </div>
      </div>

      {/* --------------------- Location Type Section --------------------- */}
      <div className="settings-form-section">
        <h3>
          Location Type Settings :
          <img
            src={editBtn}
            alt="Edit"
            className="settings-button-edit"
            onClick={() => toggleEditMode("locationTypes")}
          />
        </h3>
        <div className="settings-form-content-box" style={{ padding: 0 }}>
          <table className="settings-location-type-table">
            <thead>
              <tr>
                <th>Location Type</th>
                <th>Country</th>
                <th style={{ textAlign: "center" }}>Selected</th>
              </tr>
            </thead>
            <tbody>
              {locationTypes.map((location, index) => (
                <tr key={index}>
                  <td>{location.label}</td>
                  <td>{location.country}</td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      className="settings-checkboxes"
                      type="checkbox"
                      checked={location.selected}
                      onChange={() => toggleLocationSelection(index)}
                      disabled={!isEditMode.locationTypes}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {errors.locationTypes && <span className="settings-error">{errors.locationTypes}</span>}
        </div>
        <div className="settings-button-container">
          <button
            className="settings-button-save"
            onClick={handleSaveLocationTypes}
            disabled={!isEditMode.locationTypes}
          >
            Save
          </button>
        </div>
      </div>

      {/* --------------------- Error Code Settings Section --------------------- */}
      <div className="settings-form-section">
        <h3>
          Error Code Settings :
          <img
            src={editBtn}
            alt="Edit"
            className="settings-button-edit"
            onClick={() => toggleEditMode("errorCodeSettings")}
          />
        </h3>
        <div className="settings-form-content-box">
          <div className="settings-form-content-blocks">
            <label>
              Make <span className="settings-required">*</span>:
            </label>
            <select
              value={make}
              onChange={(e) => setMake(e.target.value)}
              disabled={!isEditMode.errorCodeSettings}
            >
              <option value="">Select Make</option>
              {makeData.map((item) => (
                <option key={item.make_id} value={item.make_name}>
                  {item.make_name}
                </option>
              ))}
            </select>
            {errors.make && <span className="settings-error">{errors.make}</span>}
          </div>
          <div className="settings-form-content-blocks">
            <label>
              Upload File <span className="settings-required">*</span>:
            </label>
            <div className="settings-input-bottom-info">
              <input
                type="file"
                onChange={handleFileChange}
                accept=".xls,.csv"
                className="settings-file-upload-input"
                disabled={!isEditMode.errorCodeSettings}
              />
              {errors.file && <span className="settings-error">{errors.file}</span>}
              <small className="settings-file-info">
                (Supported file types: .xls, .csv)
              </small>
            </div>
          </div>
        </div>
        <div className="settings-button-container">
          <button
            className="settings-button-save"
            onClick={handleSaveErrorCodeSettings}
            disabled={!isEditMode.errorCodeSettings}
          >
            Save
          </button>
        </div>
      </div>

      {/* --------------------- API Keys Section --------------------- */}
      <div className="settings-form-section">
        <h3>
          API Keys :
          <img
            src={editBtn}
            alt="Edit"
            className="settings-button-edit"
            onClick={() => toggleEditMode("apiKeys")}
          />
        </h3>
        <div className="settings-form-content-box">
          <div className="settings-form-content-blocks">
            <label>
              Make <span className="settings-required">*</span>:
            </label>
            <select
              name="make"
              value={apiKeyData.make}
              onChange={handleApiKeyInputChange}
              disabled={!isEditMode.apiKeys}
            >
              <option value="">Select Make</option>
              {makeData.map((item) => (
                <option key={item.make_id} value={item.make_name}>
                  {item.make_name}
                </option>
              ))}
            </select>
            {errors.make && <span className="settings-error">{errors.make}</span>}
          </div>
          <div className="settings-form-content-blocks">
            <label>
              Country <span className="settings-required">*</span>:
            </label>
            <select
              name="country"
              value={apiKeyData.country}
              onChange={handleApiKeyInputChange}
              disabled={!isEditMode.apiKeys}
            >
              <option value="">Select Country</option>
              {countryList.map((countryObj) => (
                <option
                  key={countryObj.country_id} // or some unique property
                  value={countryObj.country_name}
                >
                  {countryObj.country_name}
                </option>
              ))}
            </select>
            {errors.country && <span className="settings-error">{errors.country}</span>}
          </div>
          <div className="settings-form-content-blocks">
            <label>
              API Key <span className="settings-required">*</span>:
            </label>
            <input
              type="text"
              name="apiKey"
              value={apiKeyData.apiKey}
              onChange={handleApiKeyInputChange}
              disabled={!isEditMode.apiKeys}
            />
            {errors.apiKey && <span className="settings-error">{errors.apiKey}</span>}
          </div>
          <div className="settings-form-content-blocks">
            <label>
              URL <span className="settings-required">*</span>:
            </label>
            <input
              type="text"
              name="url"
              value={apiKeyData.url}
              onChange={handleApiKeyInputChange}
              disabled={!isEditMode.apiKeys}
            />
            {errors.url && <span className="settings-error">{errors.url}</span>}
          </div>
          <div className="settings-form-content-blocks">
            <label>
              Token <span className="settings-required">*</span>:
            </label>
            <input
              type="text"
              name="token"
              value={apiKeyData.token}
              onChange={handleApiKeyInputChange}
              disabled={!isEditMode.apiKeys}
            />
            {errors.token && <span className="settings-error">{errors.token}</span>}
          </div>
          <div className="settings-form-content-blocks">
            <label>
              Username <span className="settings-required">*</span>:
            </label>
            <div className="settings-password-container">
              <input
                type={showPassword.username ? "text" : "password"}
                name="username"
                value={apiKeyData.username}
                onChange={handleApiKeyInputChange}
                disabled={!isEditMode.apiKeys}
              />
              <button
                type="button"
                className="settings-toggle-visibility"
                onClick={() => togglePasswordVisibility("username")}
              >
                {showPassword.username ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {errors.username && <span className="settings-error">{errors.username}</span>}
          </div>
          <div className="settings-form-content-blocks">
            <label>
              Password <span className="settings-required">*</span>:
            </label>
            <div className="settings-password-container">
              <input
                type={showPassword.password ? "text" : "password"}
                name="password"
                value={apiKeyData.password}
                onChange={handleApiKeyInputChange}
                disabled={!isEditMode.apiKeys}
              />
              <button
                type="button"
                className="settings-toggle-visibility"
                onClick={() => togglePasswordVisibility("password")}
              >
                {showPassword.password ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {errors.password && <span className="settings-error">{errors.password}</span>}
          </div>
        </div>
        <div className="settings-button-container">
          <button
            className="settings-button-save"
            onClick={handleSaveApiKeySettings}
            disabled={!isEditMode.apiKeys}
          >
            Save
          </button>
        </div>
      </div>

      <SuccessModal isOpen={successModal} onClose={() => setSuccessModal(false)} />
    </div>
  );
};

export default SettingsForm;
