import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Stack,
  Modal,
} from "react-bootstrap";

import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import blueSolidLeftIcon from "../../assets/blue_solid_left.png";
import PlantTable from "../user/PlantTable";
import { MessageContext } from "../../App";
import { FaTimes } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function Styles() {
  return (
    <style>
      {`
               .row-card{
                 background-color:#f2f5f7;
                 text-align: left;
                 align-items: center;
                 justify-content-center;
                 box-shadow: 0px 0px 3px 1px #d7dadb;
                 border-radius: 5px;
               }

               .form-button{
                   background-color: #167db0;
                   color: white;
                   border-radius: 7px;
                   padding: 3px 20px 3px 20px
                }
               .verify-message{
                    color: #3767A2;
                    font-weight:700px;
                    font-size:16px;
                    padding-left:13px;  
                    letter-spacing: 1px;
                }

                .fw-bold{
                    font-weight:700px;
                    font-size:22px;
                    letter-spacing: 2px;
                    align-items: center;
                }

                .form-button:hover{
                background-color: white !important;
                color : black !important;
                border-color:#167db0 !important;
                }

                .form-button-sp{
                    padding: 1px 10px 1px 10px !important;
                    border-radius: 20px !important;
                }

              .card-height{
               height: 88% !important
              }           
                .user-add-form{
                    font-family: 'Open Sans';
                    font-weight: normal;
                } 
                .user-add-form .row{
                align-items: center;
                }
                .user-add-form .form-label{
                 font-weight:bold;
                 margin: 0px;
                }
                 .user-add-form .form-select{
                 font-weight:normal !important;
                 font-family: 'Open Sans';
                }

                .user-add-form .form-button{
                  font-family: 'Open Sans';
                    font-weight: bold;
                }
               .user-add-form .form-control{
                  font-family: 'Open Sans';
                    font-weight: normal;
                }
               .user-add-form .form-check-input{
                   box-shadow: 0px 1px 3px #00000029;
                   border: 1px solid #0F0D0D;
                   border-radius: 5px;
                   opacity: 1;
                   width: 20px;
                   height: 20px;

               }

               .user-form-check-radio{
               border-radius:10px !important;
               }
               .user-add-form .form-check-input:checked{
                  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path fill="%23167db0" d="M9 11.93l-3.47-3.47L4.1 10.88l5.9 5.9L20.48 6.29l-1.41-1.41L9 11.93z"/></svg>') no-repeat center;
                  }     
                  
              .entity-form-left-arrow{
                width: 23px;
                height: 23px;
                opacity: 1;
                margin-right: 5px;
               }
                .user-title{
                font-family: 'Open Sans';
                font-weight: bold;
                font-size: 20px;
                color: #0F0D0D;
                }
                
                
                 .PhoneInputInput{
                   border:none !important;
                  } 

                .PhoneInput{
                   width: 100%;
                   padding: .375rem .75rem;
                   font-size: 1rem;
                   font-weight: 400;
                   line-height: 1.5;
                   appearance: none;
                   background-color: var(--bs-body-bg);
                   background-clip: padding-box;
                   border: var(--bs-border-width) solid var(--bs-border-color);
                   border-radius: var(--bs-border-radius);
                   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
               }

             .PhoneInput--focus{
             border:0px !important;
                 border:none !important;
                 }
        `}
    </style>
  );
}

function UserAddPage({ stateChanger }) {
  const [latlngs, setLatLngs] = useState({
    lat: 12.936847666033527,
    lng: 77.57351512286992,
  });
  const [userRoles, setUserRoles] = useState([]);
  const onMessageChange = useContext(MessageContext);

  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(20);
  const [showResend, setShowResend] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [selectPlants, setSelectPlants] = React.useState([]);
  const [selectedPlantIds, setSelectedPlantIds] = useState([]); // Track selected plants
  const [showPlantModal, setShowPlantModal] = useState(false); // State to control the modal
  // const [userType, setUserType] = useState("entity_user");

  const handlePlantSelection = (plantIds) => {
    // console.log("Selected Plant IDs in UserAddPage:", plantIds);
    setFormData((prevData) => ({
      ...prevData,
      plantIds: plantIds, // Set plantIds in formData directly
    }));
  };

  const { t } = useTranslation("user");

  const submitForm = () => {
    saveUser(); // Call saveUser within submitForm
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const entityId = userData?.entityId || ""; // Extract entityId from session storage
  const authToken = sessionStorage.getItem("authToken");

  const defaultUserType =
  userData && userData.user_type === "plant_user"
    ? "plant_user"
    : "entity_user";

const [userType, setUserType] = useState(defaultUserType);
const isPlantUserLoggedIn = userData && userData.user_type === "plant_user";

  useEffect(() => {}, [latlngs]);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNo: "",
    role: "",
    entityId: entityId,
    plantIds: [],
  });

 useEffect(() => {
  // Fetch roles from backend
  fetch("https://solar-api.antsai.in/api/user/getUserRoles", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`, // Include the auth token
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      setUserRoles(data); // Update the state with fetched roles
    })
    .catch((error) => console.error("Error fetching user roles:", error));
}, []);


  const navToAdmin = () => {
    navigate("/admin");
  };

  const [warningShown, setWarningShown] = useState({
    firstName: false,
    lastName: false,
    emailId: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Regex for validating alphabets only
    const alphabetRegex = /^[A-Za-z ]*$/;

    // Regex for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name === "firstName" || name === "lastName") {
      // Allow only alphabets for firstName and lastName
      if (alphabetRegex.test(value) || value === "") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setWarningShown((prev) => ({ ...prev, [name]: false })); // Reset warning state
      } else if (!warningShown[name]) {
        onMessageChange({
          type: "Warning",
          info: "Only alphabets are allowed for First Name and Last Name.",
        });
        setWarningShown((prev) => ({ ...prev, [name]: true })); // Set warning shown
      }
    } else if (name === "emailId") {
      // Update the state for email even if it's invalid
      setFormData((prevData) => ({
        ...prevData,
        emailId: value,
      }));

      // Show warning only once for invalid email
      if (!emailRegex.test(value) && value !== "" && !warningShown.emailId) {
        onMessageChange({
          type: "Warning",
          info: "Please enter a valid email address.",
        });
        setWarningShown((prev) => ({ ...prev, emailId: true })); // Set warning shown
      } else if (emailRegex.test(value)) {
        setWarningShown((prev) => ({ ...prev, emailId: false })); // Reset warning when valid
      }
    } else {
      // Handle other fields normally
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const entityId =
      JSON.parse(sessionStorage.getItem("userData"))?.entityId || "";

    // console.log("Entity ID from session storage:", entityId);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check for empty email
    if (!formData.emailId) {
      onMessageChange({
        type: "Warning",
        info: "Please enter an email address.",
      });
      return;
    }

    // Validate email format
    if (!emailRegex.test(formData.emailId)) {
      onMessageChange({
        type: "Warning",
        info: "Please enter a valid email address.",
      });
      return;
    }

    try {
      const response = await fetch("https://solar-api.antsai.in/checkemail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include the auth token
        },
        body: JSON.stringify({ email: formData.emailId }),
      });
      const data = await response.json();
      if (data.exists) {
        onMessageChange({ type: "Danger", info: "User already exists." });
      } else {
        saveUser(); // Proceed to save user if the email does not exist
      }
    } catch (error) {
      console.error("Error checking email:", error);
      onMessageChange({
        type: "Danger",
        info: "Failed to check email availability.",
      });
    }
  };

  const saveUser = async () => {
    const { firstName, lastName, emailId, mobileNo, role, entityId, plantIds } =
      formData;

    const payload = {
      firstName,
      lastName,
      emailId,
      mobileNo,
      role,
      entityId,
      userType,
      plantIds, // Use formData.plantIds directly
    };

    // console.log("Payload Data:", payload);

    try {
      const response = await fetch(
        "https://solar-api.antsai.in/api/user/add_user1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include the auth token
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        onMessageChange({ type: "Success", info: "User added successfully!" });
        setFormData({
          firstName: "",
          lastName: "",
          emailId: "",
          mobileNo: "",
          role: "",
          entityId: entityId,
          userType: "",
          plantIds: [],
        });
        setSelectedPlantIds([]); // Reset selected plants
      } else {
        const errorData = await response.json();
        onMessageChange({
          type: "Danger",
          info: `Failed to add user: ${errorData.message || "Unknown error"}`,
        });
      }
    } catch (error) {
      console.error("Error in adding user:", error);
      onMessageChange({
        type: "Danger",
        info: "An error occurred while adding the user. Please try again.",
      });
    }
  };

  const handlemobileNo = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      mobileNo: e,
    }));
  };

  const capitalizeWords = (str) => {
    return str
      .toLowerCase() // Ensure the entire string is lowercase first
      .split(" ") // Split by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(" "); // Join the words back together
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center user-add-form">
      <Container className="mw-100">
        <Styles />
        <Row className="mt-2 mb-2">
          <Col className="d-flex flex-row align-items-center justify-content-start center-block">
            <div className="text-decoration-none d-flex align-items-center">
              <img
                onClick={() => stateChanger(7)}
                className="entity-form-left-arrow"
                src={blueSolidLeftIcon}
                alt="sort"
                style={{ cursor: "pointer" }}
              />

              <span className="fw-bold text-decoration-none text-black">
                {t("user:user")}
              </span>
            </div>
          </Col>
          <Col className="d-flex flex-row align-items-center justify-content-end"></Col>
        </Row>
      </Container>
      <Container className="mw-100">
        <Form
          className="w-100 p-0 m-0 user-add-form"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <div className=" d-flex flex-column align-items-center justify-content-center w-80 p-0 mt-2">
            <div className="w-100 text-left">
              <span className="user-title">{t("user:addNewUser")}</span>
            </div>
            <Row className="w-100 row-card p-4 m-2 d-flex flex-lg-row  flex-column">
              <Col>
                <Stack as="row" gap={2} direction="vertical">
                  <Col>
                    <Form.Group controlId="userFirstname">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("user:firstname")}{" "}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />{" "}
                            <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            name="firstName" // Change 'firstname' to 'firstName'
                            value={formData.firstName || ""}
                            onChange={(e) => handleChange(e)}
                            required
                            placeholder="Enter First Name"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col span>
                    <Form.Group controlId="userLastname">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("user:lastname")}{" "}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />{" "}
                            <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            name="lastName"
                            value={formData.lastName || ""}
                            onChange={(e) => handleChange(e)}
                            required
                            placeholder="Enter Last Name"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="userEmailid">
                      <Row className="w-100">
                        <Col md={3}>
                          <Form.Label className="text-nowrap">
                            {t("user:emailid")}
                            <StarOutlinedIcon
                              style={{ color: "red", fontSize: "smaller" }}
                            />{" "}
                            <spa>:</spa>
                          </Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            name="emailId"
                            value={formData.emailId || ""}
                            onChange={(e) => handleChange(e)}
                            required
                            placeholder="Enter Email"
                            disabled={isEmailVerified} // Disable after verification
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Stack>
              </Col>
              <Col>
                <Stack gap={3} direction="vertical">
                  <Form.Group controlId="userMobile">
                    <Row className="w-100">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">
                          {t("user:mobileNo")}{" "}
                          <StarOutlinedIcon
                            style={{ color: "red", fontSize: "smaller" }}
                          />{" "}
                          :
                        </Form.Label>
                      </Col>
                      <Col md={8}>
                        <PhoneInput
                          name="mobileNo"
                          international
                          placeholder="Enter phone number"
                          value={formData.mobileNo}
                          onChange={(e) => handlemobileNo(e)}
                          maxLength={15}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid mobile number.
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>
                  {/* <div className="d-flex flex-row align-item-center justify-content-left"> */}
                  <Row className="w-100">
                    <Col md={3}>
                      <Form.Label className="text-nowrap">
                        {"User Type"}{" "}
                        <StarOutlinedIcon
                          style={{ color: "red", fontSize: "smaller" }}
                        />{" "}
                        :
                      </Form.Label>
                    </Col>
                    <Col md={3}>
                      {/* <Form.Check className="mx-2" type="radio">
                        <Form.Check.Input
                          checked={userType === "entity_user"}
                          onChange={() => setUserType("entity_user")}
                          className="user-form-check-radio"
                          type="radio"
                        />
                        <Form.Check.Label>Entity User</Form.Check.Label>
                      </Form.Check> */}
                         <Form.Check className="mx-2" type="radio">
                          <Form.Check.Input
                            checked={userType === "entity_user"}
                            onChange={() => setUserType("entity_user")}
                            className="user-form-check-radio"
                            type="radio"
                            // Disable if logged in user type is plant_user
                            disabled={isPlantUserLoggedIn}
                          />
                          <Form.Check.Label>Entity User</Form.Check.Label>
                        </Form.Check>
                    </Col>

                    <Col md={3}>
                      <Form.Check className="mx-2" type="radio">
                        <Form.Check.Input
                          checked={userType === "plant_user"}
                          onChange={() => setUserType("plant_user")}
                          className="user-form-check-radio"
                          type="radio"
                        />
                        <Form.Check.Label>Plant User</Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                  <Form.Group controlId="userRole">
                    <Row className="w-100">
                      <Col md={3}>
                        <Form.Label className="text-nowrap">
                          {t("role")}{" "}
                          <StarOutlinedIcon
                            style={{ color: "red", fontSize: "smaller" }}
                          />{" "}
                          <spa>:</spa>
                        </Form.Label>
                      </Col>
                      <Col md={8}>
                        <Form.Select
                          name="role"
                          value={formData.role || ""}
                          onChange={(e) => handleChange(e)}
                          required
                          placeholder="User Role"
                          size="md"
                        >
                          <option value="">{t("selectRole")}</option>
                          {userType === "entity_user"
                            ? userRoles
                                .filter((r) => r.role_name !== "individual")
                                .map((role) => (
                                  <option
                                    key={role.role_id}
                                    value={role.role_name}
                                  >
                                    {capitalizeWords(role.role_name)}
                                  </option>
                                ))
                            : ""}
                          {userType !== "entity_user"
                            ? userRoles.map((role) => (
                                <option
                                  key={role.role_id}
                                  value={role.role_name}
                                >
                                  {capitalizeWords(role.role_name)}
                                </option>
                              ))
                            : ""}
                        </Form.Select>
                      </Col>
                      <Col md={1}>
                        {/* <InfoIcon sx={{ color: "#167DB0", fontSize: "30px" }} />{" "} */}
                      </Col>
                    </Row>
                  </Form.Group>
                </Stack>
              </Col>
            </Row>
            <Row className="w-100">
              <PlantTable onSelectPlant={handlePlantSelection} />
            </Row>
            <Row className="w-100">
              <div className="w-100 d-flex flex-row align-items-center justify-content-end">
                <Button
                  type="submit"
                  className="ml-3 m-1 form-button"
                  onClick={handleSubmit}
                >
                  Save User
                </Button>
              </div>
            </Row>
          </div>
          {/* OTP Modal */}
          {isOtpModalOpen && (
            <div>
              <div
                className="otp-backdrop"
                onClick={() => handleCloseOtpModal()}
              ></div>
              <div className="otp-modal">
                <div className="otp-modal-content">
                  <span
                    className="otp-close-icon"
                    onClick={() => handleCloseOtpModal()}
                  >
                    <FaTimes />
                  </span>
                  <h3>Enter OTP</h3>
                  <div className="otp-inputs">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        ref={otpRefs[index]}
                        className="otp-input"
                      />
                    ))}
                  </div>
                  <div className="d-flex flex-row gap-2">
                    <div>
                      {timer > 0 ? (
                        <span className="otp-timer">Time left: {timer}s</span>
                      ) : (
                        <Button
                          className="form-button"
                          onClick={() => handleResendOtp()}
                        >
                          Resend OTP
                        </Button>
                      )}
                    </div>
                    {!isOtpValid && (
                      <p className="error-message">
                        Please enter a valid 4-digit OTP.
                      </p>
                    )}
                    <Button
                      className="form-button"
                      onClick={(e) => handleOtpSubmit(e)}
                    >
                      Submit OTP
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      </Container>
      {/* Modal for empty plantIds */}
      <Modal
        show={showPlantModal}
        onHide={() => setShowPlantModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Continue Without Plants?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to add this user without any plants?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="modal-button"
            onClick={() => setShowPlantModal(false)}
          >
            No
          </button>
          <button
            className="modal-button"
            onClick={() => {
              setShowPlantModal(false);
              saveUser(); // Or submitForm() if defined
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserAddPage;
