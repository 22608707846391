import { useEffect, useState } from "react";
import BottomHeaderBar from "./BottomHeaderBar";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { Divider } from "@mui/material";

function Profile() {
    function Styles() {
        return (
            <style>{`
                .form-button{
                    background-color: #167db0;
                    color: white;
                    border-radius: 7px;
                    padding: 3px 20px 3px 20px;
                    font-family: 'Open Sans';
                    font-weight: bold;
                }

                .form-button:hover{
                    background-color: white !important;
                    color : black !important;
                    border-color:#167db0 !important;
                }

                .profile {
                    font-family: 'Open Sans';
                    font-weight: bold;
                }    

                .profile-labels{
                    font-weight: normal !important;
                }       
                 
                .profile .row {
                    margin-bottom:5px;
                }

                /* Profile Temperature Section */
                .temperature-label {
                font-weight: bold;
                font-size: 14px;
                display: inline-block;
                }

                .required-star {
                color: red;
                font-size: 14px;
                }

                .temperature-options {
                display: flex;
                gap: 20px;
                align-items: center;
                }

                .temperature-option {
                display: flex;
                align-items: center;
                gap: 5px;
                }

                .temperature-option input[type="radio"] {
                margin-right: 5px;
                cursor: pointer;
                }

                .temperature-symbol {
                color: #157efb;
                font-size: 20px;
                font-family: "Lato, Open Sans";
                margin-right: 5px;
                }

                .temperature-label-text {
                font-size: 14px;
                font-family: "Lato, Open Sans";
                }

            `}</style>
        );
    }

    const [edit, setEdit] = useState(false);
    const [profile, setProfile] = useState({ name: 'A Gayitri', contactNumber: "9999999999", emailId: 'agayitri@gmail.com' });
    const [temperatureUnit, setTemperatureUnit] = useState('F'); // Default value is Fahrenheit (°F)

    // Fetch the temperature setting from backend or localStorage (example implementation)
    useEffect(() => {
        const savedTemp = localStorage.getItem("temperatureUnit") || "F";
        setTemperatureUnit(savedTemp);
    }, []);

    const handleTemperatureChange = (e) => {
        setTemperatureUnit(e.target.value);
    };

    const handleSaveProfile = () => {
        // Example: Save the profile data and temperature setting
        localStorage.setItem("temperatureUnit", temperatureUnit);
        setEdit(false);
    };

    return (
        <>
            <Styles />
            <BottomHeaderBar />
            <Container className="profile gap-2">
                <Row className="mt-4 mb-8">
                    <Col className="d-flex flex-row align-items-center justify-content-start">
                        <div>Profile</div>
                    </Col>
                    <Col className="d-flex flex-row align-items-center justify-content-end"></Col>
                </Row>
                <Row className="mt-2 mb-2">
                    <Col className="d-flex flex-row align-items-center justify-content-start">
                        {/* <div>Personal Information</div> */}
                    </Col>
                    <Col className="d-flex flex-row align-items-center justify-content-end">
                        {!edit ? (
                            <Button onClick={() => setEdit(true)} className="ml-3 m-1 form-button">
                                Edit
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Col>
                </Row>
                {!edit ? (
                    <>
                        {/* View Mode */}
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label>Name</Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Label>{profile.name}</Form.Label>
                            </Col>
                        </Row>
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label>Contact Number</Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Label>{profile.contactNumber}</Form.Label>
                            </Col>
                        </Row>
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label>Email Id</Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Label>{profile.emailId}</Form.Label>
                            </Col>
                        </Row>
                        <Row className="w-100">
                            <Col md={3}>
                                <Form.Label>Temperature Unit</Form.Label>
                            </Col>
                            <Col md={4}>
                                <Form.Label>{temperatureUnit === "F" ? "Fahrenheit (°F)" : "Centigrade (°C)"}</Form.Label>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        {/* Edit Mode */}
                        <Form>
                            <Form.Group controlId="name">
                                <Row className="w-100">
                                    <Col md={3}>
                                        <Form.Label>Name</Form.Label>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Control
                                            name="name"
                                            value={profile.name}
                                            type="text"
                                            onChange={(e) =>
                                                setProfile({ ...profile, name: e.target.value })
                                            }
                                            placeholder="Name"
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId="contactNumber">
                                <Row className="w-100">
                                    <Col md={3}>
                                        <Form.Label>Contact Number</Form.Label>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Control
                                            name="contactNumber"
                                            value={profile.contactNumber}
                                            type="text"
                                            onChange={(e) =>
                                                setProfile({
                                                    ...profile,
                                                    contactNumber: e.target.value,
                                                })
                                            }
                                            placeholder="Contact Number"
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId="emailId">
                                <Row className="w-100">
                                    <Col md={3}>
                                        <Form.Label>Email Id</Form.Label>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Control
                                            name="emailId"
                                            value={profile.emailId}
                                            type="text"
                                            onChange={(e) =>
                                                setProfile({ ...profile, emailId: e.target.value })
                                            }
                                            placeholder="Email Id"
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId="temperatureUnit">
  <Row className="w-100">
    <Col md={3}>
      <Form.Label className="temperature-label">Temperature <span className="required-star">*</span>:</Form.Label>
    </Col>
    <Col md={4} className="temperature-options">
      <div className="temperature-option">
        <input
          type="radio"
          id="fahrenheit"
          name="temperatureUnit"
          value="F"
          checked={temperatureUnit === "F"}
          onChange={handleTemperatureChange}
        />
        <label htmlFor="fahrenheit">
          <span className="temperature-symbol">°F</span>
          <span className="temperature-label-text">Fahrenheit</span>
        </label>
      </div>
      <div className="temperature-option">
        <input
          type="radio"
          id="celsius"
          name="temperatureUnit"
          value="C"
          checked={temperatureUnit === "C"}
          onChange={handleTemperatureChange}
        />
        <label htmlFor="celsius">
          <span className="temperature-symbol">°C</span>
          <span className="temperature-label-text">Centigrade</span>
        </label>
      </div>
    </Col>
  </Row>
</Form.Group>
                            <div className="w-100 d-flex flex-row align-items-center justify-content-end">
                                <Button onClick={handleSaveProfile} className="ml-3 m-1 form-button">
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </Container>
        </>
    );
}

export default Profile;
