import React, {useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // For eye icons
import "./Login.css";
import backgroundImage from "../assets/solar-dl-login-bg-2x.png"; // Adjust the path according to your project structure
import logo from "../assets/logog.svg";
import { MessageContext } from '../App';
import { PermissionsContext } from '../context/PermissionsContext';

const LoginCard = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [email, setEmail] = useState('');
  // Handle password visibility
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // Password visibility toggle

  const onMessageChange = useContext(MessageContext);
  const { fetchPermissions } = useContext(PermissionsContext); // <--- Grab the fetchPermissions function

  const handleLogin = async () => {
      // Test Case: Empty fields
      if (!email || !password) {
        onMessageChange({ type: 'Danger', 'info': 'Please enter both email and password.' });
        return;
      }
  
      // Test Case: Email format validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        onMessageChange({ type: 'Danger', 'info': 'Invalid email format.' });
        return;
      }
  
      // Test Case: Password length and character validation
      if (password.length > 30) {
        onMessageChange({ type: 'Danger', 'info': 'Password should not exceed 30 characters.' });
        return;
      }
  
    const payload = {
      email,
      password,
    };
  
    try {
      const response = await fetch("https://solar-api.antsai.in/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
  
 // ... inside handleLogin after retrieving data
if (data.userData && data.userData.token) {
  // 1) Store userData in sessionStorage
  sessionStorage.setItem("userData", JSON.stringify(data.userData));
  sessionStorage.setItem("authToken", data.userData.token);
  
  // Optionally, also store userValue if needed
  sessionStorage.setItem("userValue", data.userData.userValue);

  // 2) Extract role, type, and userValue from userData
  const userRole = data.userData.user_role;
  const userType = data.userData.user_type;
  const userValue = data.userData.userValue;  // Ensure this is set in the data
  const token = data.userData.token;

  // 3) Check if role or type are missing
  if (!userRole || !userType) {
    onMessageChange({
      type: "Danger",
      info: "No valid userRole or userType returned from the server. Please contact support or try again.",
    });
    return;
  }

  // 4) Fetch permissions for this user (notice we now pass userValue as well)
  await fetchPermissions(userRole, userType, userValue, token);

  // 5) Navigate to the dashboard overview
  onMessageChange({ type: "Success", info: data.message });
  navigate("/dashboard/overview");
} else {
  onMessageChange({ type: 'Danger', info: 'Login failed: No user data found in response.' });
}
      } else {
        const errorData = await response.json();
        // alert(`Login failed: ${errorData.message}`);
        onMessageChange({
          type: 'Danger', 
          info: `Login failed: ${errorData.message}`
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      // alert("An error occurred. Please try again.");
      onMessageChange({ type: 'Danger', 'info': 'An error occurred. Please try again.' })
    }
  };

  const handleForgotpassword = () => {
    // Perform login validation here (e.g., check if fields are filled)

    // Redirect to the /dashboard/overview route
    navigate("/forgotpassword/antsfp");
  };

  // Handle password visibility toggle
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div
      className="login-card-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="login-card">
      
        <img 
          src={logo}
          className="login-logo"
        />
        <h2 className="login-title">
          <span>LOGIN</span>
        </h2>
        
        <div className="login-input-container">
          <label className="login-input-label" htmlFor="user-id">
            User ID
          </label>
          <input
            type="text"
            id="user-id"
            placeholder="Email"
            className="login-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="login-input-container">
          <label className="login-input-label" htmlFor="password">Password</label>
          <div style={{ position: 'relative' }}>
            <input
              type={isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              className="login-input"
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: '#888',
              }}
            >
              {isPasswordVisible ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
        </div>
        <button className="login-button" onClick={handleLogin}>
          LOGIN
        </button>
        <div className="login-signup-container">
          <a href="" onClick={handleForgotpassword} className="login-forgot-password-link">
            Forgot password?
          </a>
        </div>
        
      </div>
    </div>
  );
};

export default LoginCard;
