import React, { useEffect, useState } from "react";
import { ReactComponent as GridIcon } from "./../../assets/grid2_icon.svg";
import { ReactComponent as PeakPowerIcon } from "./../../assets/peak_power_icon.svg";
import "./PeakPower.css"; // Importing the CSS file
import { Card } from "react-bootstrap";
import dayjs from "dayjs";
import { json } from "react-router-dom";

const PeakPower = (totalPlants) => {

  const [units, setUnits] = useState('KWH');
  const [unitValue, setUnitValue] = useState(1000);
  const [totalPeak,setTotalPeaak] = useState(0)

  // Retrieve userData from session storage
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const token = userData?.token || "";

  // Fetch data from the API
  const [data, setData] = useState();

  useEffect(() => {
    // Retrieve userData and authToken from session storage
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    if (!token) {
      console.error("Auth token is missing from session storage.");
      return; // Exit if no auth token is available
    }

    // Fetch data with the token in the header
    const api = `https://solar-api.antsai.in/api/getPlantPeakPower`;
    fetch(api, {
      method: "POST",
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userData.token}` },
      body: JSON.stringify({'user_id':userData.userId})
    }).then((response) => {
      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }else{
      return response.json();
      }
    }).then((data) => {
      // Process and format the data
      if(data.totalPeakPower >= unitValue ){
           setUnits('Mwh')  
           setTotalPeaak((data.totalPeakPower || 0)/unitValue);        
      }else{   
      setTotalPeaak(data.totalPeakPower || 0);
      }
      setData(data)
    }).catch((error) => {
      console.error("Error fetching data:", error);
     
    });
  }, []);

  // useEffect(() => {
  //   let u = data.findIndex((unit) => unit['peak_power'] >= unitValue);
  //   if (u !== -1) {
  //     data.map((unit) => unit['peak_power'] = (unit['peak_power'] / unitValue));
  //     setUnitValue('MWH')
  //   } else {
  //     setUnitValue('KWH')
  //   }
  // }, [data])
  

  return (
    <Card className=" rounded-3 shadow-sm h-95">
      <Card.Header as={"div"} className="bg-transparent d-flex flex-row align-items-center border-bottom border-bottom-2 shadow-sm border-secondary-subtle">
        <GridIcon width={12} height={20} />
        <div className="production-title">Peak Power</div>
      </Card.Header>
      <Card.Body>
        <div className="peakpower-wrapper d-flex flex-row">
          <PeakPowerIcon width={200} height={200} />
          <div className="d-flex flex-column pl-3 ml-5 m-0">
            {/* <span className="peakpower-value">{`${data.reduce((acc, item) => (acc + item['peak_power']), 0)} : ${units}`}</span> */}
            <span className="peakpower-value">{ `${totalPeak} ${units}`} </span>
            <div className="align-self-center">Total plants : {data?.plantDocuments?.length || 0}</div>
            <div className="align-self-center" style={{ fontSize: 12 }}>Last Read: {dayjs().format('YYYY/MM/DD LT')}</div>
          </div>
        </div>

      </Card.Body>
    </Card>
  );
};

export default PeakPower;
